import { Spin, Typography } from 'antd'
import { useContext } from 'react'

import { LanguageContext } from 'helpers'
import './style.scss'

export const Table = ({ loading, initials, address, clientType, square, phone, email}) => {
    const { language } = useContext(LanguageContext)
    return (
        <div className="table no-printme">
            <div className="table__left">
                <div className="table__row-first">
                    <Typography className="table__title">{language === 'ru' ? `ФИО`: `FCs`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{initials}</Typography>
                    }
                </div>
                <div className="table__row-second">
                    <Typography className="table__title">{language === 'ru' ? `Адрес`: `Address`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc__address">{address}</Typography>
                    }
                </div>
                <div className="table__row-end">
                    <Typography className="table__title">{language === 'ru' ? `Тип клиента`: `Client type`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{clientType}</Typography>
                    }
                </div>
            </div>
            <div className="table__right">
                <div className="table__row-first">
                    <Typography className="table__title">{language === 'ru' ? `Площадь помещения`: `Room area`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{square}</Typography>
                    }
                </div>
                <div className="table__row-second">
                    <Typography className="table__title">{language === 'ru' ? `Номер телефона`: `Phone number`}</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{phone}</Typography>
                    }
                </div>
                <div className="table__row-end">
                    <Typography className="table__title">Email</Typography>
                    {loading ? <Spin className="spin"/> :
                        <Typography className="table__desc">{email}</Typography>
                    }
                </div>
            </div>
        </div>
    )
}