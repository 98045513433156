import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestClientsPicker = (lang) =>
    fetcher.requestToReceive({
        url: 'picker/clients',
        params: {lang},
        method: HTTP_METHODS.GET
    })

export const requestClientPickerApi = (id) =>
    fetcher.requestToReceive({
        url: `picker/clients/${id}`,
        method: HTTP_METHODS.GET
    })

export const requestEsimatePicker = (id, lang) =>
    fetcher.requestToReceive({
        url: `picker/clients/${id}/estimate`,
        params: {lang},
        method: HTTP_METHODS.GET
    })

    export const requestClientCheckedPickerApi = (id) =>
    fetcher.requestToReceive({
        url: `picker/clients/${id}/checked`,
        method: HTTP_METHODS.PATCH,
    })
