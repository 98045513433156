import { notification } from 'antd'

import { addClientadmin, clientsAdminLoadSuccess, error, finish, loading } from './clientsSlice'
import { requestAddClientAdminApi, requestClientsAdminApi, requestEditClientAdminApi } from 'utils'

export const requestClientsAdmin = (stage, type, status, date_to, date_from, language) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestClientsAdminApi(stage, type, status, date_to, date_from, language)
        dispatch(clientsAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestEditClientAdmin = (id, data) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditClientAdminApi(id, data)
        dispatch(requestClientsAdmin())
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestAddClientAdmin = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddClientAdminApi(data)
        if(responce.data.data) {
            dispatch(addClientadmin(responce.data.data))
        }
        dispatch(requestClientsAdmin())
    } catch (err) {
        dispatch(error({ error: err }))
        // notification.error({
        //     message: 'Ошибка',
        //     description: err.response?.data?.message,
        // })
    } finally {
        dispatch(finish())
    }
}