import { Typography, List, Button, Spin, Empty, Form, Input } from 'antd'
import { useEffect, useState,useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import TextArea from 'antd/es/input/TextArea'

import { requestActionsAdmin, requestClientAdmin, requestEstimateAdmin, requestMarkersAdmin, requestPLanAdmin } from 'redux/configStore'
import { AdminPlan, BtnGroupUploaderAdmin, EstimateTable, ImageVideoUploader, ModalConfirm, ModalSuccess, StatisticsAdmin } from 'components/_molecules'
import { requestClientCheckedAdmin, requestClientNotReadyAdmin, requestClientReadyAdmin } from 'redux/configStore/admin/client/action'
import { BtnBack, ButtonPrimary, CardEquipmentAdmin, Table, Modal } from 'components/_atoms'
import { requestEditClientAdmin } from 'redux/configStore/admin/clients/action'
import { EDIT_LINK_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ReactComponent as PrintIcon } from 'images/printIcon.svg'
import { ReactComponent as IconExcel } from 'images/excel.svg'
import { ReactComponent as IconEdit } from 'images/edit.svg'
import { ReactComponent as EditIcon } from 'images/edit.svg'
import { Gallery } from 'components/_organisms'
import { routes } from 'router'
import './style.scss'
import { useContext } from 'react'
import { LanguageContext } from 'helpers'

export const AdminLayout = () => {
    const [form] = Form.useForm()
    const params = useParams()
    const dispatch = useDispatch()

    const clientId = params.id

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalOpenMeasurer, setIsModalOpenMeasurer] = useState(false)

    const dataStatistics = useSelector((state) => state.actionsAdmin.actionsAdmin)
    const dataClient = useSelector((state) => state.clientAdmin.clientAdmin)
    const loadingClient = useSelector((state) => state.clientAdmin.isLoading)

    const dataPlan = useSelector((state) => state.planAdmin.planAdmin)
    const errorPlan = useSelector((state) => state.planAdmin.error)
    const loadingPlan = useSelector((state) => state.planAdmin.isLoading)

    const dataMarkers = useSelector((state) => state.markersAdmin.markersAdmin)
    const markersLoading = useSelector((state) => state.markersAdmin.isLoading)

    const dataEstimate = useSelector((state) => state.estimateAdmin.estimateAdmin)

    const { language } = useContext(LanguageContext)
    const disable = dataClient.date_ready !== null ? true : false
    const disablePicker = dataClient.date_checked !== null ? true : false
    const [disableButton, setDisableButton] = useState(false)
    const [link, setLink] = useState(dataClient.link)
    const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isModalOpenSuccessMeasurer, setIsModalOpenSuccessMeasurer] = useState(false)

    const handlePrint = () => {
        window.print()
    }

    const hasNullSelection = () => {
        return dataMarkers.some(device => device.device_categories
            .some(category => category.device_selection === null))
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const handleOkSuccess = () => {
        setIsModalOpenSuccess(false)
    }
    const handleCancelSuccess = () => {
        setIsModalOpenSuccess(false)
    }
    
    const confirmSelect = () => {
        setIsModalOpenSuccess(true)
        dispatch(requestClientCheckedAdmin(clientId))
    }

    const handleSuccess = () => {
        setIsModalOpenSuccess(false)
        setIsModalOpen(true)
    }
    
    const makeСhanges = () => {
        dispatch(requestClientNotReadyAdmin(clientId, dataPlan?.id))
    }

    const confirmSelectMeasurer = () => {
        setIsModalOpenSuccessMeasurer(true)
    }

    const handleOkMeasurer = () => {
        setIsModalOpenMeasurer(false)
    }

    const handleCancelMeasurer = () => {
        setIsModalOpenMeasurer(false)
    }

    const handleCancelSuccessMeasurer = () => {
        setIsModalOpenSuccessMeasurer(false)
    }

    const handleSuccessMeasurer = () => {
        setIsModalOpenSuccessMeasurer(false)
        setIsModalOpenMeasurer(true)
        dispatch(requestClientReadyAdmin(clientId, dataPlan?.id))
    }

    const handleOkSuccessMeasurer = () => {
        setIsModalOpenSuccessMeasurer(false)
    }

    const onChangeNote = (event) => {
        const data = {
            info_type: dataClient.type,
            name: dataClient.name,
            address: dataClient.address,
            area: dataClient.info?.area,
            phone: dataClient.info?.phone,
            email: dataClient.info?.email,
            note: event.target.value
        }
        const dataLegal = {
            info_type: dataClient.type,
            address: dataClient.address,
            area: dataClient.info?.contact?.area,
            phone: dataClient.info?.contact?.phone,
            email: dataClient.info?.contact?.email,
            note: event.target.value
        }
        if (clientId !== null) {
            (dataClient.type === "App\\Models\\Company") ?
                dispatch(requestEditClientAdmin(clientId, dataLegal, language))
                : dispatch(requestEditClientAdmin(clientId, data, language))
        }
    }

    const showModalEdit = () => {
        setIsModalEditOpen(true)
    }
    const handleOkEdit = () => {
        setIsModalEditOpen(false)
    }
    const handleCancelEdit = () => {
        setIsModalEditOpen(false)
    }

    const onFinish = (values) => {
        const data = {
            info_type: dataClient.type,
            name: dataClient.name,
            address: dataClient.address,
            area: dataClient.info?.area,
            phone: dataClient.info?.phone,
            email: dataClient.info?.email,
            note: dataClient.note,
            link: values.link
        }
        const dataLegal = {
            info_type: dataClient.type,
            address: dataClient.address,
            area: dataClient.info?.contact?.area,
            phone: dataClient.info?.contact?.phone,
            email: dataClient.info?.contact?.email,
            note: dataClient.note,
            link: values.link
        }
        if (clientId !== null) {
            (dataClient.type === "App\\Models\\Company") ?
                dispatch(requestEditClientAdmin(clientId, dataLegal, language))
                : dispatch(requestEditClientAdmin(clientId, data, language))
        }
        setLink(values.link)
        setIsModalEditOpen(false)
    }

    const handleEditLink = () => {
        showModalEdit()
    }

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !EDIT_LINK_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useEffect(() => {
        if(clientId !== null) {
            dispatch(requestPLanAdmin(clientId))
            dispatch(requestClientAdmin(clientId, language))
            dispatch(requestActionsAdmin(clientId, language))
            dispatch(requestMarkersAdmin(dataPlan.id))
            dispatch(requestEstimateAdmin(clientId, language))
        }
    }, [dispatch, dataPlan?.path, clientId, disable, language])

    useEffect(() => {
        setDisableButton(hasNullSelection())
    }, [dataMarkers])

    useLayoutEffect(() => {
        setLink(dataClient.link)
        form.setFieldsValue({
            link: link,
        })
    }, [form, dataClient])

    return (
        <>
            <div className="wrapper-admin no-printme">
                <BtnBack route={routes.orders} />
            </div>
            <div className="wrapper__content no-printme">
                <div className="admin-title-select">
                    <Typography className="wrapper__content_title">{language === 'ru'? `Клиент`: `Client`}</Typography>
                    {!disablePicker &&
                        <Link to={`/admin-edit-client/${dataClient.id}`}>
                            <EditIcon className="admin-title-select__edit-icon" />
                        </Link>
                    }
                </div>
                <div>
                    {loadingClient ? <Spin className="spin"/>:
                        <>
                            <Typography className="admin-title-select__employee">
                                <span>{dataClient.user?.role?.title}:</span> {`${dataClient.user?.firstname} ${dataClient.user?.surname}`}
                            </Typography>
                            {dataClient.picker_user_id &&
                                <Typography className="admin-title-select__employee">
                                    <span>{dataClient.picker_user_id?.role?.title}</span>  {`${dataClient.picker_user_id?.firstname} ${dataClient.picker_user_id?.surname}`}
                                </Typography>
                            }
                        </>
                    }
                </div>
            </div>
            {dataClient.type === "App\\Models\\Contact" ?
                <Table
                    loading={loadingClient}
                    initials={dataClient.name}
                    address={dataClient.address}
                    square={dataClient.info?.area}
                    phone={dataClient.info?.phone}
                    email={dataClient.info?.email}
                    clientType={language === 'ru' ? `Физическое лицо`: `Physical person`}
                /> :
                <Table
                    loading={loadingClient}
                    initials={dataClient.name}
                    address={dataClient.address}
                    square={dataClient.info?.contact.area}
                    phone={dataClient.info?.contact.phone}
                    email={dataClient.info?.contact.email}
                    clientType={language === 'ru' ? `Юридическое лицо`: `Legal person`}
                />
            }
            <div className="wrapper__content">
                <Typography className="wrapper__content_title">{language === 'ru'? `Заметка`: `Note`}</Typography>
            </div>
            <div className="wrapper__content">
                {loadingClient ? <Spin className="spin"/>:
                    <TextArea
                        className="note"
                        placeholder={language === 'ru'? `Введите заметку`: `Enter note`}
                        defaultValue={dataClient.note}
                        onChange={(e) => onChangeNote(e)}
                    />
                }
            </div>
            {loadingClient ? <Spin className="spin"/>:
                <div className="google-excel">
                    <IconExcel className="google-excel__icon" />
                    <Link to={link}>
                        <Typography className="google-excel__title">{link}</Typography>
                    </Link>
                    <IconEdit className="google-excel__icon-edit" onClick={handleEditLink}/>
                </div>
            }
            <Modal
                isModalOpen={isModalEditOpen}
                handleOk={handleOkEdit}
                handleCancel={handleCancelEdit}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            name="link"
                            label={language === 'ru'? `Изменить ссылку на Excel`: `Change the link to Excel`}
                            initialValue={dataClient.link}
                            key={`link_${dataClient.id}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={language === 'ru'? `Изменить ссылку`: `Change the link`} />
                        </Form.Item>
                    </Form>
                }
            />
            <div className="wrapper__content no-printme">
                <div className="admin-title-select">
                    <Typography className="wrapper__content_title">{language === 'ru'? `Планировка`: `Layout`}</Typography>
                </div>
            </div>
            {!disablePicker && 
                <BtnGroupUploaderAdmin
                    image={dataPlan?.path}
                    idPlan={dataPlan.id}
                    errorPlan={errorPlan}
                    disable={disable}
                    role={false}
                />
            }
            {!errorPlan &&
                <>
                    {loadingPlan ? <Spin className="spin-list" /> :
                        <>
                            <AdminPlan
                                loading={loadingPlan}
                                markersLoading={markersLoading}
                                disable={disable}
                                dataPlan={dataPlan}
                                markers={dataMarkers}
                            />
                            {markersLoading ? <Spin className="spin-list"/>:
                                <>
                                    <ImageVideoUploader
                                        image={dataPlan?.path}
                                        errorPlan={errorPlan}
                                        disable={disable}
                                        role='admin'
                                    />
                                    <Gallery
                                        flag='admin'
                                        disable={disablePicker}
                                        loading={loadingPlan}
                                        videoArray={dataPlan?.videos}
                                        imgArray={dataPlan?.images}
                                    />
                                    <div className="btn-send-measurer no-printme">
                                        {!disable && disablePicker ? 
                                            <>
                                                <Button className="btn-group__send_btn" onClick={confirmSelectMeasurer}>
                                                    <Typography className="btn-group__send_btn_title">
                                                        {language === 'ru'? `Отправить на подбор`: `Send for selection`}
                                                    </Typography>
                                                </Button>
                                                <Typography className="btn-group__send_desc">
                                                    <div className="btn-group__send_desc_icon"></div> 
                                                    {language === 'ru'? `После отправки редактирование будет невозможно`: `After sending, editing will not be possible`}
                                                </Typography>
                                            </>:
                                            <>
                                                {!disablePicker && disable ?
                                                    <Button className="btn-group__send_btn" onClick={makeСhanges}>
                                                        <Typography className="btn-group__send_btn_title">
                                                            {language === 'ru'? `Внести корректировки`: `Make adjustments`}
                                                        </Typography>
                                                    </Button>:
                                                    <>
                                                        <Button className="btn-group__send_btn" onClick={confirmSelectMeasurer} disabled={disable}>
                                                            <Typography className={!disable ? "btn-group__send_btn_title": "btn-group__send_btn_title-disable"}>
                                                                {language === 'ru'? `Отправить на подбор`: `Send for selection`}
                                                            </Typography>
                                                        </Button>
                                                        <Typography className="btn-group__send_desc">
                                                            <div className="btn-group__send_desc_icon"></div>
                                                            {language === 'ru'? `После отправки редактирование будет невозможно`: `After sending, editing will not be possible`}
                                                        </Typography>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="wrapper__content no-printme">
                                        <Typography className="wrapper__content_title">{language === 'ru'? `Выбор оборудования`: `Equipment selection`}</Typography>
                                    </div>
                                    <div className="no-printme">
                                        <List
                                            className="plan-list"
                                            grid={{
                                                column: 2,
                                            }}
                                            locale={{
                                                emptyText: (
                                                    <Empty description={language === 'ru'? `Нет данных для отображения`: `No data to display`} />
                                                )
                                            }}
                                            dataSource={dataMarkers}
                                            renderItem={(item, index) => (
                                                <List.Item key={item.id}>
                                                    <CardEquipmentAdmin
                                                        count={index}
                                                        marker={item}
                                                        markerId={item.id}
                                                        planId={dataPlan.id}
                                                        disable={disablePicker}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                    <div className="btn-send-measurer no-printme">
                                        <Button className="btn-group__send_btn" onClick={confirmSelect} disabled={disablePicker ? disablePicker: disableButton}>
                                            <Typography className={!disablePicker && !disableButton ? "btn-group__send_btn_title" : "btn-group__send_btn_title-disable"}>
                                                {language === 'ru'? `Отправить на оценку`: `Submit for evaluation`}
                                            </Typography>
                                        </Button>
                                        <Typography className="btn-group__send_desc">
                                            <div className="btn-group__send_desc_icon"></div>
                                            {language === 'ru'? `После отправки редактирование будет невозможно`: `After sending, editing will not be possible`}
                                        </Typography>
                                    </div>
                                </>
                            }
                            {!!dataEstimate.length &&
                                <>
                                    <EstimateTable array={dataEstimate} />
                                    <div className="print no-printme">
                                        <Button className="print__btn" onClick={handlePrint}>
                                            <Typography className={language === 'ru' ? "print__btn__title": "print__btn__title-en"}>{language === 'ru'? `Распечатать`: `Print`}</Typography>
                                            <PrintIcon className="print__btn__icon"/>
                                        </Button>
                                    </div>
                                </>
                            }
                            <StatisticsAdmin data={dataStatistics} client={dataClient}/>
                        </>
                    }
                </>
            }
            <ModalConfirm
                isModalOpen={isModalOpenSuccess}
                handleOk={handleOkSuccess}
                handleCancel={handleCancelSuccess}
                handleSuccess={handleSuccess}
            />
            <ModalSuccess
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                title={language === 'ru'? `Подбор отправлен успешно`: `The selection was sent successfully`}
                role="measurer"
            />
            <ModalConfirm
                isModalOpen={isModalOpenSuccessMeasurer}
                handleOk={handleOkSuccessMeasurer}
                handleCancel={handleCancelSuccessMeasurer}
                handleSuccess={handleSuccessMeasurer}
            />
            <ModalSuccess
                isModalOpen={isModalOpenMeasurer}
                handleOk={handleOkMeasurer}
                handleCancel={handleCancelMeasurer}
                title={language === 'ru'? `Замер отправлен успешно`: `The measurement was sent successfully`}
                role="measurer"
            />
        </>
    )
}