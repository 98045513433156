import { Link, useNavigate } from 'react-router-dom'
import { Input, Typography } from 'antd'
import { useContext } from 'react'

import { requestChangeContractPriceAdmin } from 'redux/configStore/admin/client/action'
import { ReactComponent as EditIcon } from 'images/edit.svg'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageContext, getDate } from 'helpers'
import './style.scss'

export const CardClient = ({ item, link, linkEdit }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state) => state.user.user)

    const { language } = useContext(LanguageContext)

    const client = (item.type === 'App\\Models\\Contact') ? `${language === 'ru'? `Физическое лицо`: `Physical person`}` : `${language === 'ru'? `Юридическое лицо`: `Legal person`}`
    const cheked =
        (item.is_ready === false) ? `${language === 'ru'? `Ожидает замера`: `Waiting for measurement`}` :
        (item.date_checked !== null) ? `${language === 'ru'? `Подбор выполнен`: `Selection is completed`}` : `${language === 'ru'? `Замер выполнен`: `Measurement is completed`}`
    const route = `/${link}/${item.id}`
    const routeEdit = `/${linkEdit}/${item.id}`

    const headerClick = () => {
        navigate(route)
    }

    const onChangeCount = (e, id) => {
        const inputValue = e.target.value
        const formattedValue = parseFloat(inputValue.replace(/,/g, '')).toFixed(2)
      
        const data = {
            contract_price: formattedValue,
        }

        dispatch(requestChangeContractPriceAdmin(id, data))
    }
 
    return (
        <div className="card-wrapper" key={item.id}>
            <div className="card-header">
                <div className="card-header_texts" onClick={headerClick}>
                    <Typography className="card-header_texts__name">{item.name}</Typography>
                </div>
                {item.is_ready === false &&
                    <Link to={routeEdit}>
                        <EditIcon className="card-header_edit"/>
                    </Link>
                }
            </div>
            <Link to={route}>
                <div className="card-info"> 
                    <div className="card-info__left">
                        <Typography className="card-info__left_street">{item.address}</Typography>
                        <Typography className="card-info__left_client">
                            <span>{language === 'ru'? `Клиент: `: `Client: `}</span>{client}
                        </Typography>
                    </div>
                    <div className="card-info__right">
                        <Typography className="card-info__right_title">{language === 'ru'? `Статус:`: `Status`}</Typography>
                        {(cheked === 'Ожидает замера' || cheked === 'Waiting for measurement') ?
                            <Typography className="card-info__right_desc">{cheked}</Typography> :
                            <Typography className="card-info__right_desc-green">{cheked}</Typography>
                        }
                    </div>
                </div>
            </Link>
            {(user.id === 1 || user.id === 4) &&
                <div className="card-price">
                    <Link to={route}>
                        <Typography className="card-price__title">{language === 'ru'? `Сумма заказа:`: `Order amount:`}</Typography>
                    </Link>
                    <Input
                        className="statistic__price__update-price"
                        defaultValue={item.contract_price}
                        onChange={(e) => onChangeCount(e, item.id)}
                        disabled={item.date_checked}
                        type="number"
                        step="0.01"
                    />
                </div>
            }
            <Link to={route}>
                <div className="card-date">
                    <div className="card-date__row">
                        <Typography className="card-date__row__title">{language === 'ru'? `Заказ создан:`: `Order is created:`}</Typography>
                        <Typography className="card-date__row__desc">
                            {item.created_at !== null && getDate(item.created_at)}
                        </Typography>
                    </div>
                    <div className="card-date__row">
                        <Typography className="card-date__row__title">{language === 'ru'? `Замер выполнен:`: `Measurement is completed:`}</Typography>
                        <Typography className="card-date__row__desc">
                            {item.date_ready !== null && getDate(item.date_ready)}
                        </Typography>
                    </div>
                    <div className="card-date__row">
                        <Typography className="card-date__row__title">{language === 'ru'? `Подбор выполнен:`: `Selection is completed:`}</Typography>
                        <Typography className="card-date__row__desc">
                            {item.date_checked !== null && getDate(item.date_checked)}
                        </Typography>
                    </div>
                </div>
            </Link>
        </div>
    )
}