import { notification } from 'antd'
import { clientAdd, clientsLoadSuccess, error, finish, loading } from './clientsSlice'
import {
    requestAddClientMeasurer,
    requestClientsMeasurer,
    requestEditClientMeasurerApi
} from 'utils'

export const requestClientsInfo = (lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestClientsMeasurer(lang)
        dispatch(clientsLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestClientAdd = (data) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestAddClientMeasurer(data)
        if(responce.data.data) {
            dispatch(clientAdd(responce.data.data))
        }
        dispatch(requestClientsInfo())
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}

export const requestEditClientMeasurer = (id, data, lang) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditClientMeasurerApi(id, data, lang)
        dispatch(requestClientsInfo())
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}
