import { Button, Input, Spin, Typography, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import TextArea from 'antd/es/input/TextArea'
import { useState, useEffect, useLayoutEffect } from 'react'

import { requestPLanMeasurer, requestClientMeasurer, requestActionsMeasurer, requestMarkersMeasurer,
    requestClientReadyMeasurer, requestClientNotReadyMeasurer, requestEditClientMeasurer } from 'redux/configStore'
import { MeasurerPlan, Statistics, ModalSuccess, BtnGroupUploader,
    ImageVideoUploader, 
    ModalConfirm} from 'components/_molecules'
import { ReactComponent as IconExcel } from 'images/excel.svg'
import { ReactComponent as IconEdit } from 'images/edit.svg'
import { BtnBack, ButtonPrimary, Modal, Table } from 'components/_atoms'
import { Gallery } from 'components/_organisms'
import { routes } from 'router'
import './style.scss'
import { EDIT_LINK_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { useContext } from 'react'
import { LanguageContext } from 'helpers'

export const MeasurerLayout = () => {
    const params = useParams()
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const id = params.id
    const { language } = useContext(LanguageContext)
    const dataPlan = useSelector((state) => state.plan.plan)
    const errorPlan = useSelector((state) => state.plan.error)
    const loadingPlan = useSelector((state) => state.plan.isLoading)
    const dataMarkers = useSelector((state) => state.markers.markers)
    const dataStatistics = useSelector((state) => state.actions.actions)
    const markersLoading = useSelector((state) => state.markers.isLoading)
    const loadingClient = useSelector((state) => state.clientMeasurer.isLoading)
    const dataClient = useSelector((state) => state.clientMeasurer.clientMeasurer)

    const [link, setLink] = useState(dataClient.link)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isModalEditOpen, setIsModalEditOpen] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)
    
    const disable = dataClient.date_ready !== null ? true : false
    const disablePicker = dataClient.date_checked !== null ? true : false
    
    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const handleOkSuccess = () => {
        setIsModalOpenSuccess(false)
    }

    const handleCancelSuccess = () => {
        setIsModalOpenSuccess(false)
    }

    const confirmSelect = () => {
        setIsModalOpenSuccess(true)
    }

    const handleSuccess = () => {
        setIsModalOpenSuccess(false)
        setIsModalOpen(true)
        dispatch(requestClientReadyMeasurer(id, dataPlan?.id))
    }

    const makeСhanges = () => {
        dispatch(requestClientNotReadyMeasurer(id, dataPlan?.id))
    }

    const onChangeNote = (event) => {
        const data = {
            info_type: dataClient.type,
            name: dataClient.name,
            address: dataClient.address,
            area: dataClient.info?.area,
            phone: dataClient.info?.phone,
            email: dataClient.info?.email,
            note: event.target.value
        }
        const dataLegal = {
            info_type: dataClient.type,
            address: dataClient.address,
            area: dataClient.info?.contact?.area,
            phone: dataClient.info?.contact?.phone,
            email: dataClient.info?.contact?.email,
            note: event.target.value
        }
        if (id !== null) {
            (dataClient.type === "App\\Models\\Company") ?
                dispatch(requestEditClientMeasurer(id, dataLegal, 'en'))
                : dispatch(requestEditClientMeasurer(id, data, 'en'))
        }
    }

    const showModalEdit = () => {
        setIsModalEditOpen(true)
    }
    const handleOkEdit = () => {
        setIsModalEditOpen(false)
    }
    const handleCancelEdit = () => {
        setIsModalEditOpen(false)
    }

    const onFinish = (values) => {
        const data = {
            info_type: dataClient.type,
            name: dataClient.name,
            address: dataClient.address,
            area: dataClient.info?.area,
            phone: dataClient.info?.phone,
            email: dataClient.info?.email,
            note: dataClient.note,
            link: values.link
        }
        const dataLegal = {
            info_type: dataClient.type,
            address: dataClient.address,
            area: dataClient.info?.contact?.area,
            phone: dataClient.info?.contact?.phone,
            email: dataClient.info?.contact?.email,
            note: dataClient.note,
            link: values.link
        }
        if (id !== null) {
            (dataClient.type === "App\\Models\\Company") ?
                dispatch(requestEditClientMeasurer(id, dataLegal, 'en'))
                : dispatch(requestEditClientMeasurer(id, data, 'en'))
        }
        setLink(values.link)
        setIsModalEditOpen(false)
    }

    const handleEditLink = () => {
        showModalEdit()
    }

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !EDIT_LINK_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useEffect(() => {
        if(id !== null) {
            dispatch(requestPLanMeasurer(id))
            dispatch(requestMarkersMeasurer(dataPlan.id))
            dispatch(requestActionsMeasurer(id, language))
            dispatch(requestClientMeasurer(id))
        }
    }, [dispatch, id, dataPlan?.path, disable, language])

    useLayoutEffect(() => {
        setLink(dataClient.link)
        form.setFieldsValue({
            link: link,
        })
    }, [form, dataClient])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.home} />
            </div>
            <div className="wrapper__content">
                <Typography className="wrapper__content_title">{language === 'ru'? `Клиент`: `Client`}</Typography>
            </div>
            {dataClient.type === "App\\Models\\Contact" ?
                <Table
                    loading={loadingClient}
                    initials={dataClient.name}
                    address={dataClient.address}
                    square={dataClient.info?.area}
                    phone={dataClient.info?.phone}
                    email={dataClient.info?.email}
                    clientType={language === 'ru' ? `Физическое лицо`: `Physical person`}
                /> :
                <Table
                    loading={loadingClient}
                    initials={dataClient.name}
                    address={dataClient.address}
                    square={dataClient.info?.contact.area}
                    phone={dataClient.info?.contact.phone}
                    email={dataClient.info?.contact.email}
                    clientType={language === 'ru' ? `Юридическое лицо`: `Legal person`}
                />
            }
            <div className="wrapper__content">
                <Typography className="wrapper__content_title">{language === 'ru'? `Заметка`: `Note`}</Typography>
            </div>
            <div className="wrapper__content">
                {loadingClient ? <Spin className="spin"/>:
                    <TextArea
                        className="note"
                        placeholder={language === 'ru'? `Введите заметку`: `Enter note`}
                        defaultValue={dataClient.note}
                        onChange={(e) => onChangeNote(e)}
                    />
                }
            </div>
            {loadingClient ? <Spin className="spin"/>:
                <div className="google-excel">
                    <IconExcel className="google-excel__icon" />
                    <Link to={link}>
                        <Typography className="google-excel__title">{link}</Typography>
                    </Link>
                    <IconEdit className="google-excel__icon-edit" onClick={handleEditLink}/>
                </div>
            }
            <Modal
                isModalOpen={isModalEditOpen}
                handleOk={handleOkEdit}
                handleCancel={handleCancelEdit}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            name="link"
                            label={language === 'ru'? `Изменить ссылку на Excel`: `Change the link to Excel`}
                            initialValue={dataClient.link}
                            key={`link_${dataClient.id}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={language === 'ru'? `Изменить ссылку`: `Change the link`} />
                        </Form.Item>
                    </Form>
                }
            />
            <BtnGroupUploader
                image={dataPlan?.path}
                idPlan={dataPlan.id}
                errorPlan={errorPlan}
                disable={disable}
                role={true}
            />
            {!errorPlan &&
                <>
                    {(loadingPlan && markersLoading) ? <Spin className="spin-list" /> :
                        <>
                            <MeasurerPlan
                                loading={loadingPlan}
                                markersLoading={markersLoading}
                                disable={disable}
                                dataPlan={dataPlan}
                                markers={dataMarkers}
                            />
                            <div className="btn-send-measurer">
                                {!disable && disablePicker ? 
                                    <>
                                        <Button className="btn-group__send_btn" onClick={confirmSelect}>
                                            <Typography className="btn-group__send_btn_title">
                                                {language === 'ru'? `Отправить на подбор`: `Send for selection`}
                                            </Typography>
                                        </Button>
                                        <Typography className="btn-group__send_desc">
                                            <div className="btn-group__send_desc_icon"></div>
                                            {language === 'ru'? `После отправки редактирование будет невозможно`: `After sending, editing will not be possible`}
                                        </Typography>
                                    </>:
                                    <>
                                        {!disablePicker && disable ?
                                            <Button className="btn-group__send_btn" onClick={makeСhanges}>
                                                <Typography className="btn-group__send_btn_title">
                                                    {language === 'ru'? `Внести корректировки`: `Make adjustments`}
                                                </Typography>
                                            </Button>:
                                            <>
                                                <Button className="btn-group__send_btn" onClick={confirmSelect} disabled={disable}>
                                                    <Typography className={!disable ? "btn-group__send_btn_title": "btn-group__send_btn_title-disable"}>
                                                        {language === 'ru'? `Отправить на подбор`: `Send for selection`}
                                                    </Typography>
                                                </Button>
                                                <Typography className="btn-group__send_desc">
                                                    <div className="btn-group__send_desc_icon"></div>
                                                    {language === 'ru'? `После отправки редактирование будет невозможно`: `After sending, editing will not be possible`}
                                                </Typography>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <ImageVideoUploader
                                image={dataPlan?.path}
                                errorPlan={errorPlan}
                                disable={disable}
                            />
                            <Gallery
                                flag='measurer'
                                disable={disable}
                                loading={loadingPlan}
                                videoArray={dataPlan?.videos}
                                imgArray={dataPlan?.images}
                            />
                            <Statistics data={dataStatistics}/>
                        </>
                    }
                    <ModalConfirm
                        isModalOpen={isModalOpenSuccess}
                        handleOk={handleOkSuccess}
                        handleCancel={handleCancelSuccess}
                        handleSuccess={handleSuccess}
                    />
                    <ModalSuccess
                        isModalOpen={isModalOpen}
                        handleOk={handleOk}
                        handleCancel={handleCancel}
                        title={language === 'ru'? `Замер отправлен успешно`: `The measurement was sent successfully`}
                        role="measurer"
                    />
                </>
            }
        </>
    )
}
