import { estimateLoadSuccess, error, finish } from './estimateSlice'
import { requestEsimatePicker } from 'utils'

export const requestEstimatePicker = (id, language) => async (dispatch) => {
    try {
        const responce = await requestEsimatePicker(id, language)
        if(responce.data.data) {
            dispatch(estimateLoadSuccess(responce.data.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}
