import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Spin, Typography } from 'antd'
import moment from 'moment'

import { requestClientsAdmin, requestClientsTypeAdmin, requestRolesAdmin } from 'redux/configStore'
import { FilterAdmin, ListClient } from 'components/_molecules'
import { ReactComponent as AddIcon } from 'images/addIcon.svg'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminOrders = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const { language } = useContext(LanguageContext)

    const dataClients = useSelector((state) => state.clientsAdmin.clientsAdmin)
    const loadingClients = useSelector((state) => state.clientsAdmin.isLoading)

    const roles = useSelector((state) => state.rolesAdmin.rolesAdmin)
    const rolesLoading = useSelector((state) => state.rolesAdmin.isLoading)

    const clientsType = useSelector((state) => state.clientsTypeAdmin.clientsTypeAdmin)
    const clientsTypeLoading = useSelector((state) => state.clientsTypeAdmin.isLoading)

    const endOfMonth = moment(new Date()).endOf('month')
    const startOfMonth = moment(new Date()).startOf('month')

    const [roleId, setRoleId] = useState('')
    const [status, setStatus] = useState('')
    const [disable, setDisable] = useState(true)
    const [clientType, setClientType] = useState('')
    const [dateRange, setDateRange] = useState([startOfMonth.format('YYYY-MM-DD'), endOfMonth.format('YYYY-MM-DD')])

    useEffect(() => {
        dispatch(requestClientsAdmin(roleId, clientType, status, dateRange[1], dateRange[0], language))
    }, [dispatch, roleId, clientType, status, dateRange, language])

    useEffect(() => {
        dispatch(requestRolesAdmin(language))
        dispatch(requestClientsTypeAdmin(language))
    }, [language])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.home} />
                <Typography className="orders-title">{language === 'ru' ? `Заказы`: `Orders`}</Typography>
            </div>
            <FilterAdmin
                page="orders"
                roles={roles}
                roleId={roleId}
                status={status}
                disable={disable}
                dateRange={dateRange}
                setStatus={setStatus}
                setRoleId={setRoleId}
                setDisable={setDisable}
                clientType={clientType}
                clientsType={clientsType}
                rolesLoading={rolesLoading}
                setDateRange={setDateRange}
                setClientType={setClientType}
                clientsTypeLoading={clientsTypeLoading}
            />
            <div className="wrapper__content">
                <Typography className="wrapper__content_title">{language === 'ru' ? `Все заказы`: `All orders`}</Typography>
                <AddIcon className="measurer-add" onClick={() => navigate(routes.adminAddClient)}/>
            </div>
            {loadingClients ? <Spin className="spin-list" /> :
                <ListClient link='admin-layout' linkEdit='admin-edit-client' data={dataClients} />
            }
        </>
    )
}