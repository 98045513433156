import { Form, Radio, Button, Typography, Input, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import { useState, useEffect } from 'react'
import 'react-phone-input-2/lib/style.css'

import { requestClientAdd, requestTypeClientsMeasurer } from 'redux/configStore'
import { MEASURER_CLIENT_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { routes } from 'router'
import './style.scss'
import { LanguageContext } from 'helpers'
import { useContext } from 'react'

export const MeasurerAddClient = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)
    const [phone, setPhone] = useState('')
    const [type, setType] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const clientError = useSelector((state) => state.clients.error)
    const isClientLoading = useSelector((state) => state.clients.isLoading)
    const clientsTypeLoading = useSelector((state) => state.clientsTypeMeasurer.isLoading)
    const clientsType = useSelector((state) => state.clientsTypeMeasurer.clientsTypeMeasurer)

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        navigate(routes.default)
    }

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !MEASURER_CLIENT_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    const handlePhoneChange = (value, country) => {
        setPhone(value)
    }

    const onFinish = (values) => {
        const data = {
            info_type: 'App\\Models\\Contact',
            name: values.name,
            address: values.adress,
            area: values.square,
            phone: values.phone,
            email: values.email,
            link: values.link,
            note: values.note,
        }
        const dataLegal = {
            info_type: 'App\\Models\\Company',
            company_name: values.nameOrg,
            company_address: values.addressZip,
            payment_account: values.pay,
            post_address: values.legalAddr,
            bank_name: values.nameBank,
            bank_bik: values.bic,
            unp: values.unp,
            okpo: values.okpo,
            charge: values.acting,
            charge_base: values.inFace,
            name: values.name,
            address: values.adress,
            area: values.square,
            phone: values.phone,
            email: values.email,
            link: values.link,
            note: values.note,
        }
        if (type === 'App\\Models\\Company') {
            dispatch(requestClientAdd(dataLegal))
        } else {
            dispatch(requestClientAdd(data))
        }
        showModal()
    }

    useEffect(() => {
        dispatch(requestTypeClientsMeasurer(language))
    }, [dispatch, language])

    useEffect(() => {
        setType(clientsType[0]?.type)
        form.setFieldsValue({
            remember: true,
            type: clientsType[0]?.type
        })
    }, [clientsTypeLoading, form])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.home} />
            </div>
            <Typography className="client-add__title title-one">{language === 'ru' ? `Клиент`: `Client`}</Typography>
            {clientsTypeLoading ? <Spin className="spin"/> : (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    className="client-add__form"
                    onValuesChange={onValuesChange}
                >
                    <Form.Item
                        name="name"
                        label={language === 'ru' ? `Имя *`: `Name *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите имя`: `Enter a name`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="adress"
                        label={language === 'ru' ? `Адрес *`: `Address *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите адрес`: `Enter the address`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        label={language === 'ru' ? `Тип клиента`: `Type client`}
                    >
                        <Radio.Group onChange={onChangeType}>
                            {clientsType.map((item, index) =>
                                <Radio value={item.type} key={index}>{item.title}</Radio>
                            )}
                        </Radio.Group>
                    </Form.Item>
                    {type === 'App\\Models\\Company' &&
                        <>
                           <Form.Item
                                name="nameOrg"
                                label={language === 'ru' ? `Наименование организации`: `Name of the organization`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="legalAddr"
                                label={language === 'ru' ? `Юридический адрес`: `Legal address`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="pay"
                                label={language === 'ru' ? `Расчётный счёт`: `Payment account`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="addressZip"
                                label={language === 'ru' ? `Почтовый адрес с индексом`: `Postal address with ZIP code`}
                            >
                                <Input placeholder={language === 'ru' ? `г. Батуми ул. Баку 9–11, 6000`: `Batumi Baku str. 9-11, 6000`} />
                            </Form.Item>
                            <Form.Item
                                name="nameBank"
                                label={language === 'ru' ? `Наименование банка`: `Name of the bank`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="bic"
                                label={language === 'ru' ? `БИК банка`: `BIC bank`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="unp"
                                label={language === 'ru' ? `УНП`: `UNP`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="OCPO"
                                label={language === 'ru' ? `ОКПО`: `OCPO`}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="inFace"
                                label={language === 'ru' ? `В лице`: `In person`}
                            >
                                <Input placeholder={language === 'ru' ? `Директора Алиева Т. М.`: `Director T. M. Aliyev .`} />
                            </Form.Item>
                            <Form.Item
                                name="acting"
                                label={language === 'ru' ? `Действующего на основании`: `Acting on the basis of`}
                            >
                                <Input placeholder={language === 'ru' ? `Устава / Договора`: `Charter / Agreement`} />
                            </Form.Item>
                        </>
                    }
                    <Form.Item
                        name="square"
                        label={language === 'ru' ? `Площадь помещения, м2 *`: `Room area, m2 *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите площадь помещения`: `Enter the area of the room`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={language === 'ru' ? `Номер телефона *`: `Phone number *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите номер телефона`: `Enter your phone number`}` }]}
                    >
                        <PhoneInput
                            country={'ge'}
                            value={phone}
                            onChange={handlePhoneChange}
                            inputComponent={Input}
                            inputProps={{
                                name: 'phone',
                                required: true,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="link"
                        label={language === 'ru' ? `Ссылка на Excel *`: `Link to Excel *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите ссылку на Excel`: `Enter the link to Excel`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label={language === 'ru' ? `Заметка`: `Note`}
                    >
                        <Input className="add-emplyee__form__note" placeholder={language === 'ru' ? `Введите текст`: `Enter text`} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className="client-add__form__btn"
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitDisabled || isClientLoading}
                        >
                            <Typography className="client-add__form__btn_title">
                                {language === 'ru' ? `Сохранить`: `Save`}
                            </Typography>
                        </Button>
                    </Form.Item>
                </Form>
            )}
            {!isClientLoading && !clientError && (
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Клиент сохранен`: `Client saved`}
                    route={routes.home}
                />
            )}
        </>
    )
}
