import { requestPlanPicker } from '../plan'
import { clientLoadSuccess, error, finish, loading } from './clientSlice'
import { requestClientCheckedPickerApi, requestClientPickerApi } from 'utils'

export const requestClientPicker = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestClientPickerApi(id)
        dispatch(clientLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestClientCheckedPicker = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestClientCheckedPickerApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(requestClientPicker(id))
        dispatch(requestPlanPicker(id))
        dispatch(finish())
    }
}