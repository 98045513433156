import { Typography, Input, Form, Button, Select, Spin } from 'antd'
import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { requestAddDeviceAdmin, requestDevicesCategoryAdmin, requestDevicesTypeAdmin,
    requestManufacturerAdmin, requestProtocolAdmin } from 'redux/configStore'
import { ADMIN_DEVICE_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'
import './style.scss'

export const AdminAddDevice = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)
    
    const devicesError = useSelector((state) => state.devicesAdmin.error)
    const protocols = useSelector((state) => state.protocolAdmin.protocolAdmin)
    const isDevicesLoading = useSelector((state) => state.devicesAdmin.isLoading)
    const protocolsLoading = useSelector((state) => state.protocolAdmin.isLoading)
    const devicesType = useSelector((state) => state.devicesTypeAdmin.devicesTypeAdmin)
    const devicesTypeLoading = useSelector((state) => state.devicesTypeAdmin.isLoading)
    const manufacturersLoading = useSelector((state) => state.manufacturerAdmin.isLoading)
    const manufacturers = useSelector((state) => state.manufacturerAdmin.manufacturerAdmin)
    const devicesCategoryLoading = useSelector((state) => state.devicesCategoryAdmin.isLoading)
    const devicesCategory = useSelector((state) => state.devicesCategoryAdmin.devicesCategoryAdmin)

    const [deviceType, setDeviceType] = useState(null)
    const [protocolsId, setProtocolsId] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedTypes, setSelectedTypes] = useState(null)
    const [manufacturerId, setManufacturerId] = useState(false)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

    const dataDevicesCategory = devicesCategory.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const dataManufacturers = manufacturers.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const dataProtocol = protocols.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const handleManufacturerChange = (value) => {
        setManufacturerId(value)
    }

    const handleProtocolChange = (value) => {
        setProtocolsId(Array.isArray(value) ? value : [value]);
    }

    const handleDeviceTypeChange = (value) => {
        setDeviceType(value)
    }

    const handleDeviceCategoryChange = (categoryId) => {
        const types = devicesType.filter(type => type.device_category.id === categoryId)
        setSelectedTypes(types.map(type => ({
            label: type.title,
            value: type.id
        })))
        setDeviceType(null)
    }
    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_DEVICE_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    const onFinish = (values) => {
        const data = {
            device_type_id: deviceType,
            product_code: values.product_code,
            title: values.title,
            count: values.count,
            link: values.link,
            description: values.description,
            manufacturer_id: manufacturerId,
            protocols: protocolsId,
        }
        dispatch(requestAddDeviceAdmin(data))
        showModal()
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        navigate(routes.catalog)
    }

    useEffect(() => {
        dispatch(requestDevicesTypeAdmin('', language))
        dispatch(requestDevicesCategoryAdmin(language))
        dispatch(requestManufacturerAdmin(language))
        dispatch(requestProtocolAdmin(language))
    }, [dispatch, language])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.catalog} />
                <Typography className="add-device_title">{language === 'ru' ? `Устройство`: `Device`}</Typography>
            </div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                className="add-emplyee__form"
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="funcDevice"
                    label={language === 'ru' ? `Функция устройства *`: `Device function *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Выберите функцию устройства`: `Select the device function`}` }]}
                >
                    {devicesCategoryLoading ? <Spin className="spin"/> :
                        <Select
                            className="add-device_select"
                            options={dataDevicesCategory}
                            onChange={handleDeviceCategoryChange}
                        />
                    }
                </Form.Item>
                <Form.Item
                    name="typeDevice"
                    label={language === 'ru' ? `Тип устройства *`: `Device Type *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Выберите тип устройства`: `Select the device type`}` }]}
                >
                    {devicesTypeLoading ? <Spin className="spin"/> :
                        <Select
                            className="add-device_select"
                            value={deviceType}
                            options={selectedTypes}
                            onChange={handleDeviceTypeChange}
                        />
                    }
                </Form.Item>
                <Form.Item
                    name="manufacturer"
                    label={language === 'ru' ? `Производитель *`: `Manufacturer *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Выберите производителя`: `Choose a manufacturer`}` }]}
                >
                    {manufacturersLoading ? <Spin className="spin"/> :
                        <Select
                            className="add-device_select"
                            options={dataManufacturers}
                            onChange={handleManufacturerChange}
                        />
                    }
                </Form.Item>
                <Form.Item
                    name="protocol"
                    label={language === 'ru' ? `Протокол *`: `Protocol *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Выберите протокол`: `Select a protocol`}` }]}
                >
                    {protocolsLoading ? <Spin className="spin"/> :
                        <Select
                            className="add-device_select"
                            options={dataProtocol}
                            onChange={handleProtocolChange}
                            value={protocolsId}
                            mode="multiple"
                        />
                    }
                </Form.Item>
                <Form.Item
                    name="product_code"
                    label={language === 'ru' ? `Код товара *`: `Product code *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите код товара`: `Enter the product code`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="title"
                    label={language === 'ru' ? `Название *`: `Name *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите название`: `Enter a name`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="count"
                    label={language === 'ru' ? `Количество на складе *`: `Quantity in stock *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите количество`: `Enter the quantity`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="link"
                    label={language === 'ru' ? `Ссылка на устройство *`: `Link to the device *`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите ссылку на устройство`: `Enter the link to the device`}` }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={language === 'ru' ? `Описание`: `Description`}
                    rules={[{ required: true, message: `${language === 'ru' ? `Введите описание`: `Enter a description`}` }]}
                >
                    <Input className="add-emplyee__form__note" placeholder={language === 'ru' ? `Введите текст`: `Enter the text`} />
                </Form.Item>
                <Form.Item shouldUpdate>
                    <Button
                        className="client-add__form__btn"
                        type="primary"
                        htmlType="submit"
                        disabled={isSubmitDisabled || isDevicesLoading}
                    >
                        <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                    </Button>
                </Form.Item>
            </Form>
            {!isDevicesLoading && !devicesError && 
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Устройство успешно сохранено`: `Device saved successfully`}
                    route={routes.catalog}
                />
            }
        </>
    )
}