import { clientAdminLoadSuccess, error, finish, loading } from './clientAdminSlice'
import { requestPLanAdmin } from '../floorPlanAdmin'
import { requestMarkersAdmin } from '../markersAdmin'
import { requestClientAdminApi, requestClientNotReadyAdminApi, requestClientReadyAdminApi } from 'utils'
import { requestClientCheckedAdminApi, requestUpdateContractPriceClientApi } from 'utils/admin/client/api'
import { notification } from 'antd'

export const requestClientAdmin = (id, lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestClientAdminApi(id, lang)
        dispatch(clientAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestClientReadyAdmin= (id, planId) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestClientReadyAdminApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestClientAdmin(id))
        dispatch(requestPLanAdmin(id))
        dispatch(requestMarkersAdmin(planId))
    }
}

export const requestClientNotReadyAdmin = (id, planId) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestClientNotReadyAdminApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPLanAdmin(id))
        dispatch(requestClientAdmin(id))
        dispatch(requestMarkersAdmin(planId))
    }
}

export const requestClientCheckedAdmin = (id) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestClientCheckedAdminApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(requestClientAdmin(id))
        dispatch(requestPLanAdmin(id))
        dispatch(finish())
    }
}

export const requestChangeContractPriceAdmin = (id, data) => async (dispatch) => {
    try {
        await requestUpdateContractPriceClientApi(id, data)
    } catch (err) {
        dispatch(error({ error: err }))
        notification.error({
            message: 'Ошибка',
            description: err.response?.data?.message,
        })
    } finally {
        dispatch(finish())
    }
}