import { useState, useEffect, useLayoutEffect, useContext } from 'react'
import { Typography, Input, Form, Button, Select, Spin } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { requestDeviceAdmin, requestDevicesTypeAdmin, requestDevicesCategoryAdmin, requestManufacturerAdmin, requestProtocolAdmin } from 'redux/configStore'
import { requestDeleteDeviceAdmin, requestEditDeviceAdmin } from 'redux/configStore/admin/devices/action'
import { ADMIN_DEVICE_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ModalSuccess } from 'components/_molecules'
import { BtnBack } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import { routes } from 'router'

export const AdminEditDevice = () => {
    const params = useParams()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const idDevice = params.id

    const [selectedTypes, setSelectedTypes] = useState()
    const { language } = useContext(LanguageContext)

    const devicesType = useSelector((state) => state.devicesTypeAdmin.devicesTypeAdmin)
    const devicesTypeLoading = useSelector((state) => state.devicesTypeAdmin.isLoading)

    const devicesCategory = useSelector((state) => state.devicesCategoryAdmin.devicesCategoryAdmin)
    const devicesCategoryLoading = useSelector((state) => state.devicesCategoryAdmin.isLoading)

    const device = useSelector((state) => state.deviceAdmin.deviceAdmin)
    const deviceLoading = useSelector((state) => state.deviceAdmin.isLoading)

    const manufacturers = useSelector((state) => state.manufacturerAdmin.manufacturerAdmin)
    const manufacturersLoading = useSelector((state) => state.manufacturerAdmin.isLoading)
    
    const protocols = useSelector((state) => state.protocolAdmin.protocolAdmin)
    const protocolsLoading = useSelector((state) => state.protocolAdmin.isLoading)

    const isDevicesLoading = useSelector((state) => state.devicesAdmin.isLoading)
    const devicesError = useSelector((state) => state.devicesAdmin.error)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [deviceType, setDeviceType] = useState('')
    const [manufacturerId, setManufacturerId] = useState(device.manufacturer?.id)
    const [protocolsId, setProtocolsId] = useState(() => {
        return device.protocols?.map(item => item.id) ?? []
    })

    const dataDevicesCategory = devicesCategory.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const dataManufacturers = manufacturers.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const handleManufacturerChange = (value) => {
        setManufacturerId(value)
    }

    const dataProtocol = protocols.map(obj => ({
        value: obj.id,
        label: obj.title,
    }))

    const handleProtocolChange = (value) => {
        setProtocolsId(Array.isArray(value) ? value : [value])
    }

    const handleDeviceTypeChange = (value) => {
        setDeviceType(value)
    }

    const onFinish = (values) => {
        const data = {
            device_type_id: deviceType,
            product_code: values.product_code,
            title: values.title,
            count: values.count,
            link: values.link,
            description: values.description,
            manufacturer_id: manufacturerId,
            protocols: protocolsId,
        }
        if (idDevice !== null) {
            dispatch(requestEditDeviceAdmin(idDevice, data))
        }
        showModal()
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        navigate(routes.catalog)
    }

    useEffect(() => {
        dispatch(requestDevicesTypeAdmin('', language))
        dispatch(requestDevicesCategoryAdmin(language))
        dispatch(requestManufacturerAdmin(language))
        dispatch(requestProtocolAdmin(language))
        if (idDevice !== null) {
            dispatch(requestDeviceAdmin(idDevice))
        }
    }, [dispatch, idDevice, language])

    const handleDeviceCategoryChange = (categoryId) => {
        const types = devicesType.filter(type => type.device_category.id === categoryId)
        setSelectedTypes(types.map(type => ({
            label: type.title,
            value: type.id
        })))
        setDeviceType(null)
    }

    useEffect(() => {
        const categoryId = device.device_type?.device_category?.id
        const types = devicesType.filter(type => type.device_category.id === categoryId)
        setSelectedTypes(types.map(type => ({
            label: type.title,
            value: type.id
        })))
    }, [devicesType, device.device_type?.device_category?.id])

    const deleteDevice = () => {
        if (idDevice !== undefined) {
            dispatch(requestDeleteDeviceAdmin(idDevice))
        }
        navigate(routes.catalog)
    }

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_DEVICE_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    useLayoutEffect(() => {
        setDeviceType(device.device_type?.id)
        form.setFieldsValue({
            funcDevice: device.device_type?.device_category?.id,
            typeDevice: device.device_type?.id,
            product_code: device.product_code,
            title: device.title,
            count: device.count,
            link: device.link,
            description: device.description,
            manufacturer_id: manufacturerId,
            protocols: protocolsId,
         })
    }, [form, deviceLoading, device, manufacturerId, protocolsId])

    return (
        <>
            <div className="wrapper-admin">
                <BtnBack route={routes.catalog} />
                <Typography className="add-device_title">
                    {language === 'ru' ? `Устройство`: `Device`}
                </Typography>
                <IconDelete className="add-device-delete" onClick={deleteDevice}/>
            </div>
            {deviceLoading ? <Spin className="spin-list" /> :
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    className="add-emplyee__form"
                    onValuesChange={onValuesChange}
                >
                    <Form.Item
                        name="funcDevice"
                        label={language === 'ru' ? `Функция устройства *`: `Device function *`}
                        key={`funcDevice_${device.id}`}
                    >
                        {devicesCategoryLoading ? <Spin className="spin"/> :
                            <Select
                                defaultValue={device.device_type?.device_category?.id}
                                className="add-device_select"
                                options={dataDevicesCategory}
                                onChange={handleDeviceCategoryChange}
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        name="typeDevice"
                        label={language === 'ru' ? `Тип устройства *`: `Device Type *`}
                        key={`typeDevice_${device.id}`}
                    >
                        {devicesTypeLoading ? <Spin className="spin"/> :
                            <Select
                                className="add-device_select"
                                value={deviceType}
                                options={selectedTypes}
                                onChange={handleDeviceTypeChange}
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        name="manufacturer"
                        label={language === 'ru' ? `Производитель *`: `Manufacturer *`}
                        rules={[{ required: true, message: 'Выберите производителя' }]}
                        key={`manufacturer_${device.id}`}
                    >
                        {manufacturersLoading ? <Spin className="spin"/> :
                            <Select
                                defaultValue={manufacturerId}
                                className="add-device_select"
                                options={dataManufacturers}
                                onChange={handleManufacturerChange}
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        name="protocol"
                        label={language === 'ru' ? `Протокол *`: `Protocol *`}
                        rules={[{ required: true, message: 'Выберите протокол' }]}
                        key={`protocol_${device.id}`}
                    >
                        {protocolsLoading ? <Spin className="spin"/> :
                            <Select
                                defaultValue={protocolsId}
                                className="add-device_select"
                                options={dataProtocol}
                                onChange={handleProtocolChange}
                                mode="multiple"
                            />
                        }
                    </Form.Item>
                    <Form.Item
                        name="product_code"
                        label={language === 'ru' ? `Код товара *`: `Product code *`}
                        key={`product_code_${device.id}`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите код товара`: `Enter the product code`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="title"
                        label={language === 'ru' ? `Название *`: `Name *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите название`: `Enter a name`}` }]}
                        key={`title_${device.id}`}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="count"
                        key={`count_${device.id}`}
                        label={language === 'ru' ? `Количество на складе *`: `Quantity in stock *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите количество`: `Enter the quantity`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="link"
                        key={`link_${device.id}`}
                        label={language === 'ru' ? `Ссылка на устройство *`: `Link to the device *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите ссылку на устройство`: `Enter the link to the device`}` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        key={`description_${device.id}`}
                        label={language === 'ru' ? `Описание *`: `Description *`}
                        rules={[{ required: true, message: `${language === 'ru' ? `Введите описание`: `Enter a description`}` }]}
                    >
                            <Input
                                className="add-emplyee__form__note"
                                placeholder={language === 'ru' ? `Введите текст`: `Enter the text`}
                            />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            className="client-add__form__btn"
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitDisabled || isDevicesLoading}
                        >
                            <Typography className="client-add__form__btn_title">{language === 'ru' ? `Сохранить`: `Save`}</Typography>
                        </Button>
                    </Form.Item>
                </Form>
            }
            {!isDevicesLoading && !devicesError &&
                <ModalSuccess
                    isModalOpen={isModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    title={language === 'ru' ? `Устройство успешно изменено`: `The device has been successfully changed`}
                    route={routes.catalog}
                />
            }
        </>
    )
}
