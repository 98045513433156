export const phys = "App\\Models\\Contact"
export const legal = "App\\Models\\Company"

export const MEASURER_CLIENT_FORM_REQUIRED_FIELDS = [
    'name',
    'adress',
    'square',
    'phone',
    'link'
]

export const ADMIN_USER_FORM_REQUIRED_FIELDS = [
    'role',
    'firstname',
    'surname',
    'login',
    'birthdate',
    'citizenship',
    'address',
    'phone',
    'when_hired',
]

export const ADMIN_DEVICE_FORM_REQUIRED_FIELDS = [
    'typeDevice',
    'manufacturer',
    'protocol',
    'product_code',
    'title',
    'count',
    'link',
    'description',
]

export const USER_PROFILE_FORM_REQUIRED_FIELDS = [
    'firstname',
    'surname',
    'login',
    'phone',
    'email',
]

export const RESET_PASSWORD_FORM_REQUIRED_FIELDS = [
    'newPassword',
    'confirmPassword'
]

export const EDIT_LINK_FORM_REQUIRED_FIELDS = [
    'link'
]

export const ADMIN_TRANSCTION_FORM_REQUIRED_FIELDS = [
    'date',
    'sum',
]