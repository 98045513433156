import { Typography, Collapse, Spin, Input, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as IconEdit } from 'images/editIcon.svg'
import { ReactComponent as IconAdd } from 'images/addIcon.svg'
import { ButtonPrimary, Modal } from 'components/_atoms'
import {
    requestDevicesTypeAdmin,
    requesAddDeviceTypeAdmin,
    requesEditDeviceTypeAdmin,
    requestDevicesCategoryAdmin,
    requestDeleteDeviceTypeAdmin,
    requestDeleteDeviCategoryAdmin,
    requestAddDevicesCategoryAdmin,
    requestEditDevicesCategoryAdmin,
} from 'redux/configStore'
import './style.scss'
import { useContext } from 'react'
import { LanguageContext } from 'helpers'

const { Panel } = Collapse

export const SettingsMenu = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const devicesType = useSelector((state) => state.devicesTypeAdmin.devicesTypeAdmin)
    const devicesTypeLoading = useSelector((state) => state.devicesTypeAdmin.isLoading)

    const devicesCategory = useSelector((state) => state.devicesCategoryAdmin.devicesCategoryAdmin)
    const devicesCategoryLoading = useSelector((state) => state.devicesCategoryAdmin.isLoading)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [btnTitle, setBtnTitle] = useState('')
    const [formLabel, setFormLabel] = useState('')
    const [categoryId, setCategoryId] = useState(null) 
    const [typeId, setTypeId] = useState(null)
    
    const handleAddCategory = () => {
        setBtnTitle(`${language === 'ru' ? `Добавить категорию`: `Add a category`}`)
        setFormLabel(`${language === 'ru' ? `Введите категорию`: `Enter a category`}`)
        setTypeId(null)
        showModal()
    }

    const handleChangeCategory = (categoryId, categoryTitle) => {
        setBtnTitle(`${language === 'ru' ? `Изменить категорию`: `Change the category`}`)
        setFormLabel(`${language === 'ru' ? `Изменить категорию`: `Change the category`}`)
        setCategoryId(categoryId)
        setTypeId(null)
        showModal()
    }

    const handleDeleteCategory = (idCategory) => {
        dispatch(requestDeleteDeviCategoryAdmin(idCategory))
    }

    const handleAddType = (idCategory, categoryTitle) => {
        setBtnTitle(`${language === 'ru' ? `Добавить тип`: `Add a type`}`)
        setFormLabel(`${language === 'ru' ? `Добавить тип`: `Add a type`}`)
        setCategoryId(idCategory)
        setTypeId(null)
        showModal()
    }

    const handleChangeType = (idType, idCategory, categoryTitle, typeTitle) => {
        setBtnTitle(`${language === 'ru' ? `Изменить тип`: `Change the type`}`)
        setFormLabel(`${language === 'ru' ? `Изменить тип`: `Change the type`}`)
        setTypeId(idType)
        setCategoryId(idCategory)
        showModal()
    }

    const handleDeleteType = (idType) => {
        dispatch(requestDeleteDeviceTypeAdmin(idType))
    }

    const onFinish = (values) => {
        if(formLabel === 'Введите категорию' || formLabel === 'Enter a category') {
            const data = {
                title: values.title
            }
            dispatch(requestAddDevicesCategoryAdmin(data))
        }
        if(btnTitle === 'Изменить категорию' || btnTitle === 'Change the category') {
            const data = {
                title: values.title
            }
            dispatch(requestEditDevicesCategoryAdmin(categoryId, data))
        }
        if(btnTitle === 'Изменить тип' || btnTitle === 'Change the type') {
            const data = {
                title: values.title,
                device_category_id: categoryId,
            }
            dispatch(requesEditDeviceTypeAdmin(typeId, data))
        }
        if (btnTitle === 'Добавить тип' || btnTitle === 'Add a type') {
            const data = {
                title: values.title,
                device_category_id: categoryId
            }
            dispatch(requesAddDeviceTypeAdmin(data))
        }
        form.resetFields()
        handleCancel()
    } 

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        dispatch(requestDevicesTypeAdmin('', language))
        dispatch(requestDevicesCategoryAdmin(language))
    }, [language])

    return (
        <div className="settings-menu">
            <div className="settings-menu__header">
                <Typography className="settings-menu__header__title">{language === 'ru' ? `Настройки категорий и типов`: `Category and type settings`}</Typography>
                <IconAdd className="settings-menu__header__icon" onClick={handleAddCategory}/>
            </div>
            {devicesCategoryLoading ? <Spin className="spin-list"/>:
                <Collapse
                    bordered={false}
                    expandIcon=""
                >
                    {devicesCategory.map((category) => {
                            return (
                                <Panel
                                    header={
                                        <Typography className="settings-menu__item-title">
                                            <IconAdd
                                                className="settings-menu__icon-add"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleAddType(category.id, category.title)
                                                }}
                                            />
                                            <IconEdit
                                                className="settings-menu__icon-edit"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleChangeCategory(category.id, category.title)
                                                }}
                                            />
                                            <IconDelete className="settings-menu__icon-delete"
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleDeleteCategory(category.id)
                                                }}
                                            />
                                            {category.title}
                                        </Typography>
                                    }
                                    key={category.id}
                                >
                                    {devicesTypeLoading ? <Spin className="spin"/>:
                                        <>
                                            {devicesType.filter((typeItem) => typeItem.device_category.id === category.id).map((type) =>
                                                <Typography className="settings-menu__item-text">
                                                    <IconEdit className="settings-menu__icon-edit" onClick={() => handleChangeType(type.id, category.id, category.title, type.title)}/>
                                                    <IconDelete className="settings-menu__icon-delete" onClick={() => handleDeleteType(type.id)}/>
                                                    {type.title}
                                                </Typography>
                                            )}
                                        </>
                                    }
                                </Panel>
                            )})}
                </Collapse>
            }
            <Modal
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                    >
                        <Form.Item
                            name="title"
                            label={formLabel}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={btnTitle} />
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}