import { Input, Typography, Spin, Form, DatePicker } from 'antd'
import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { requestAddTransactionsAdmin, requestDeleteTransactionsAdmin, requestEditTransactionsAdmin, requestTransactionAdmin, requestTransactionsAdmin } from 'redux/configStore'
import { requestChangeContractPriceAdmin } from 'redux/configStore/admin/client/action'
import { ADMIN_TRANSCTION_FORM_REQUIRED_FIELDS } from 'constants/data/data'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as DateIcon } from 'images/dateIcon.svg'
import { ReactComponent as IconEdit } from 'images/editIcon.svg'
import { ReactComponent as IconAdd } from 'images/addIcon.svg'
import { ButtonPrimary, Modal } from 'components/_atoms'
import { LanguageContext, getDate } from 'helpers'
import './style.scss'
import { useLayoutEffect } from 'react'

export const StatisticsAdmin = ({ data, client }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const [sum, setSum] = useState(false)
    const [date, setDate] = useState('')
    const [btnTitle, setBtnTitle] = useState('')
    const [formLabel, setFormLabel] = useState('')
    const [statistic, setStatistic] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [transactionId, setTransactionId] = useState(null)
    
    const transactionsLoading = useSelector((state) => state.transactionsAdmin.isLoading)
    const transactions = useSelector((state) => state.transactionsAdmin.transactionsAdmin)

    const transaction = useSelector((state) => state.transactionAdmin.transactionAdmin)

    const choosePhoto = () => {
        setStatistic(true)
        setSum(false)
    }

    const chooseVideo = () => {
        setSum(true)
        setStatistic(false)
    }

    const onChangeCount = (e, id) => {
        const inputValue = e.target.value
        const formattedValue = parseFloat(inputValue.replace(/,/g, '')).toFixed(2)
        const data = {
            contract_price: formattedValue,
        }
        dispatch(requestChangeContractPriceAdmin(id, data))
    }

    const handleAddTransaction = () => {
        setTransactionId(null)
        setBtnTitle(`${language === 'ru' ? `Добавить`: `Add`}`)
        setFormLabel(`${language === 'ru' ? `Добавить транзакцию`: `Add a transaction`}`)
        showModal()
    }

    const handleDeleteTransaction = (transactionId) => {
        dispatch(requestDeleteTransactionsAdmin(client.id, transactionId))
    }

    const handleChangeTransaction = (idTransaction) => {
        setBtnTitle(`${language === 'ru' ? `Изменить`: `Change`}`)
        setFormLabel(`${language === 'ru' ? `Изменить транзакцию`: `Change transaction`}`)
        setTransactionId(idTransaction)
        showModal()
    }

    const onChangeDate = (date, dateString) => {
        setDate(dayjs(dateString, 'YYYY-MM-DD HH:mm:ss'))
    }
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const onValuesChange = (changedValues, allValues) => {
        const isSubmitDisabled = !ADMIN_TRANSCTION_FORM_REQUIRED_FIELDS.every(name => !!form.getFieldValue(name))
            || !!form.getFieldsError().filter(({ errors }) => errors.length).length
        setIsSubmitDisabled(isSubmitDisabled)
    }

    const onFinish = (values) => {
        const inputValue = values.sum
        const formattedValue = parseFloat(inputValue.replace(/,/g, '')).toFixed(2)
        if(formLabel === 'Добавить транзакцию' || formLabel === 'Add a transaction') {
            const data = {
                client_id: client.id,
                date: date.format('YYYY-MM-DD HH:mm:ss'),
                price: formattedValue
            }
            dispatch(requestAddTransactionsAdmin(client.id, data))
        }
        if(btnTitle === 'Изменить' || btnTitle === 'Change') {
            const data = {
                client_id: client.id,
                date: date.format('YYYY-MM-DD HH:mm:ss'),
                price: formattedValue
            }
            dispatch(requestEditTransactionsAdmin(client.id, transactionId, data))
        }
        form.resetFields()
        handleCancel()
    }

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        dispatch(requestTransactionsAdmin(client.id))
    }, [])

    useEffect(() => {
        if (transactionId !== null) {
            dispatch(requestTransactionAdmin(transactionId))
        }
    }, [transactionId])

    useLayoutEffect(() => {
        if (transactionId !== null) {
            setDate(dayjs(transaction.date, 'YYYY-MM-DD HH:mm:ss'))
            form.setFieldsValue({
                date: dayjs(transaction.date, 'YYYY-MM-DD HH:mm:ss'),
                sum: transaction.price
             })
        } else {
            setDate(null)
            form.setFieldsValue({
                date: null,
                sum: null
             })
        }
    }, [form, transaction, transactionId])

    return (
        <div className="gallery no-printme">
            <div className="gallery_header">
                <div className={statistic === true ? "gallery_header__left_active" : "gallery_header__left"} onClick={choosePhoto}>
                    <Typography className={statistic === true ? "gallery__title-active" : "gallery__title"}>{language === 'ru' ? `Статистика по изменениям в проекте`: `Statistics on changes in the project`}</Typography>
                </div>
                <div className={sum === true ?  "gallery_header__right_active": "gallery_header__right"} onClick={chooseVideo}>
                    <Typography className={sum === true ? "gallery__title-active" : "gallery__title"}>{language === 'ru' ? `Транзакции в проекте`: `Order amount`}</Typography>
                </div>
            </div>
            {statistic && data.map((item) => 
                <Typography className="statistic__title" key={item.id}>
                    {`${item.text} (${item.role.title} - ${item.user.firstname} ${item.user.surname}) `}
                    <span>{getDate(item.datetime)}</span>
                </Typography>
            )}
            {sum && 
                <>
                    <div className="transactions__header">
                        <Typography className="transactions__header__title">{language === 'ru' ? `Транзакции`: `Transactions in the project`}</Typography>
                        <IconAdd className="transactions__header__icon" onClick={handleAddTransaction}/>
                    </div>
                    {transactionsLoading ? <Spin className="spin"/>: 
                        <>
                            {transactions.map((transaction, index) => 
                                <Typography className="transactions__item-title">
                                    <IconEdit
                                        className="transactions__icon-edit"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleChangeTransaction(transaction.id)
                                        }}
                                    />
                                    <IconDelete className="transactions__icon-delete"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleDeleteTransaction(transaction.id)
                                        }}
                                    />
                                    {`${language === 'ru' ? `Транзакция`: `Transaction`} ${index + 1} (${transaction.date}): ${transaction.price}`}
                                </Typography>
                            )}
                        </>
                    }
                    <div className="statistic__price">
                        <Typography className="statistic__price__title">{language === 'ru' ? `Сумма контракта:`: `Contract amount:`}</Typography>
                        <Input
                            className="statistic__price__update-price"
                            defaultValue={client.contract_price}
                            onChange={(e) => onChangeCount(e, client.id)}
                            disabled={client.date_checked}
                            type="number"
                            step="0.01"
                        />
                    </div>
                </> 
            }
            <Modal
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                        onValuesChange={onValuesChange}
                    >
                        <Form.Item
                            name="date"
                            label="Дата транзакции"
                            key={`date_${transaction.id}`}
                        >
                            <DatePicker
                                placeholder=''
                                suffixIcon={<DateIcon />}
                                onChange={onChangeDate}
                                className="add-emplyee__form__date"
                                showTime
                            />
                        </Form.Item>
                        <Form.Item
                            name="sum"
                            label={formLabel}
                            key={`sum_${transaction.id}`}
                        >
                            <Input
                                type="number"
                                step="0.01"
                            />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={btnTitle} />
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}