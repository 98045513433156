import { Select, DatePicker, Radio, Typography, Input, Spin, Button } from 'antd'
import { useState, useContext } from 'react'
import moment from 'moment'

import { ReactComponent as CloseIcon } from 'images/closeListIcon.svg'
import { ReactComponent as DateIcon } from 'images/dateIcon.svg'
import { ReactComponent as SearchIcon } from 'images/search.svg'
import { LanguageContext } from 'helpers'
import './style.scss'

const { Option } = Select

export const FilterAdmin = ({
    page,
    roles,
    roleId,
    inStaff,
    setEmpty,
    setRoleId,
    setSearch,
    setInStaff,
    setNotEmpty,
    deviceType,
    clientsType,
    rolesLoading,
    deviceTypeId,
    deviceCategory,
    setDeviceTypeId,
    deviceCategoryId,
    deviceTypeLoading,
    clientsTypeLoading,
    setDeviceCategoryId,
    deviceCategoryLoading,
    clientType,
    setClientType,
    status,
    setStatus,
    setDateRange,
    disable,
    setDisable,
    dateRange,
    setAllEmployee,
    setAllCatalog
}) => {
    const [searchValue, setSearchValue] = useState('')
    const [filterCatalog, setfilterCatalog] = useState('')

    const { language } = useContext(LanguageContext)

    const onChangeStock = (value) => {
        if (value === true) {
            setNotEmpty(true)
            setEmpty('')
        } else if (value === false) {
            setNotEmpty('')   
            setEmpty(true)
        } else if (value === '') {
            setEmpty('')
            setNotEmpty('')
        }
        setfilterCatalog(value)
      }

    const onSearch = () => {
        setSearch(searchValue)
    }

    const handleClearSearch = () => {
        setSearch('')
        setSearchValue('')
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          onSearch()
        }
    }

    const onChangeInStaff = (value) => {
        setInStaff(value)
    }

    const onChangeRoleId = (value) => {
        setRoleId(value)
    }

    const onChangeClientType = (value) => {
        setClientType(value)
    }

    const onChangeStatus = (value) => {
        setStatus(value)
    }

    const handleChangeCategoryDevice = (value) => {
        setDeviceCategoryId(value)
    }

    const handleChangeTypeDevice = (value) => {
        setDeviceTypeId(value)
    }

   
    const handleDateRangeChange = (e) => {
        const value = e.target.value;
        if (page === "catalog") {
            setAllCatalog(false)
        }
        if (page === "employee") {
            setAllEmployee(false)
        }
        if (value === '2') {
            setDisable(true)
            setDateRange([moment(new Date()).format('YYYY-MM-DD')])
        } else if (value === '3') {
            setDisable(true)
            const yesterday = moment(new Date()).subtract(1, 'days')
            setDateRange([yesterday.format('YYYY-MM-DD')])   
        } else if (value === '4') {
            setDisable(true)
            const startOfYear = moment().startOf('year');
            const tomorrow = moment().add(1, 'day');
            setDateRange([startOfYear.format('YYYY-MM-DD'), tomorrow.format('YYYY-MM-DD')])
        } else if (value === '5') {
            setDisable(true)
            const startOfLastMonth = moment(new Date()).subtract(1, 'month').startOf('month')
            const endOfLastMonth = moment(new Date()).subtract(1, 'month').endOf('month')
            setDateRange([startOfLastMonth.format('YYYY-MM-DD'), endOfLastMonth.format('YYYY-MM-DD')])
        } else if (value === '1') {
            setDisable(true)
            const startOfMonth = moment(new Date()).startOf('month')
            const endOfMonth = moment(new Date()).endOf('month')
            setDateRange([startOfMonth.format('YYYY-MM-DD'), endOfMonth.format('YYYY-MM-DD')])
        } else if (value === '7') {
            setDisable(false)
        }
    }

    const onChangeDateFrom = (date, dateString) => {
        if (page === "catalog") {
            setAllCatalog(false)
        }
        if (page === "employee") {
            setAllEmployee(false)
        }
        const newDateRange = [...dateRange]
        if (date && dateString) {
            newDateRange[0] = dateString
            if (newDateRange[1]) {
                setDateRange(newDateRange)
            }
        } else {
            newDateRange[0] = undefined
            setDateRange(newDateRange)
        }
    }
    
    const onChangeDateTo = (date, dateString) => {
        if (page === "catalog") {
            setAllCatalog(false)
        }
        if (page === "employee") {
            setAllEmployee(false)
        }
        const newDateRange = [...dateRange]
        if (date && dateString) {
            newDateRange[1] = dateString
            if (newDateRange[0]) {
                setDateRange(newDateRange)
            }
        } else {
            newDateRange[1] = undefined
            setDateRange(newDateRange)
        }
    }

    const handleAllEmployee = () => {
        setAllEmployee(true)
    }

    return (
        <div className="filter-admin">
            <div className="filter-admin__header">
                <Typography className="filter-admin__header__title">{language === 'ru' ? `Фильтр`: `Filter`}</Typography>
                {page === 'catalog' &&
                    <Input className="filter-admin__header__search"
                        suffix={
                            <>
                                <SearchIcon onClick={onSearch} className="cursor"/>
                                {searchValue !== '' && (
                                <CloseIcon
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleClearSearch()
                                    }}
                                    className="cursor"
                                />)}
                            </>
                        }
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        value={searchValue}
                        onClick={handleClearSearch} 
                    />
                }
                {page === 'employee' &&
                     <Button className="btn-all" onClick={handleAllEmployee}>
                        <Typography className="btn-all__title">
                            {language === 'ru' ? `Показать всех`: `Show all`}
                        </Typography>
                    </Button>
                }
            </div>
            <div className="filter-admin__form">
                <div className="filter-admin__form__left">
                    <Typography className="filter-admin__form__title">{language === 'ru' ? `Дата:`: `Date:`}</Typography>
                    <div className="filter-admin__form__left__wrapper">
                        <Radio.Group className="filter-admin__form_container-date" onChange={handleDateRangeChange} defaultValue='1'>
                            <div className="filter-admin__form_container-date_left">
                                <Radio className="filter-admin__form__item" value='2'>
                                    <Typography className="filter-admin__form__desc">{language === 'ru' ? `Сегодня`: `Today`}</Typography>
                                </Radio>
                                <Radio className="filter-admin__form__item" value='3'>
                                    <Typography className="filter-admin__form__desc">{language === 'ru' ? `Вчера`: `Yesterday`}</Typography>
                                </Radio>
                                <Radio value='4'>
                                    <Typography className="filter-admin__form__desc">{language === 'ru' ? `За текущий год`: `Current year`}</Typography>
                                </Radio>
                            </div>
                            <div className="filter-admin__form_container-date_right">
                                <Radio className="filter-admin__form__item" value='5'>
                                    <Typography className="filter-admin__form__desc">{language === 'ru' ? `За предыдущий месяц`: `Previous month`}</Typography>
                                </Radio>
                                <Radio className="filter-admin__form__item" value='1'>
                                    <Typography className="filter-admin__form__desc">{language === 'ru' ? `За текущий месяц`: `Current month`}</Typography>
                                </Radio>
                                <Radio value='7'>
                                    <Typography className="filter-admin__form__desc">{language === 'ru' ? `За период`: `Period`}</Typography>
                                </Radio>
                            </div>
                        </Radio.Group>
                    </div>
                    <div className="filter-admin__date">
                        <div>
                            <Typography className="filter-admin__date__title">{language === 'ru' ? `С`: `From`}</Typography>
                            <DatePicker
                                className="filter-admin__date__select"
                                placeholder=''
                                suffixIcon={<DateIcon />}
                                onChange={onChangeDateFrom}
                                disabled={disable}
                            />
                        </div>
                        <div>
                            <Typography className="filter-admin__date__title">{language === 'ru' ? `По`: `To`}</Typography>
                            <DatePicker
                                className="filter-admin__date__select"
                                placeholder=''
                                suffixIcon={<DateIcon />}
                                onChange={onChangeDateTo}
                                disabled={disable}
                            />
                        </div>
                    </div>
                </div>
                <div className="filter-admin__form__right">
                    {
                    (page === 'orders') ?
                        <>
                            <div className="filter-admin__form__right__orders-first">
                                <Typography className="filter-admin__form__title">{language === 'ru' ? `Клиенты:`: `Clients:`}</Typography>
                                {clientsTypeLoading ? <Spin className="spin"/>:
                                    <Radio.Group className="filter-admin__form__radio-group" onChange={(e) => onChangeClientType(e.target.value)} value={clientType}>
                                        <Radio className="filter-admin__form__item" value=''>
                                            <Typography className="filter-admin__form__desc">{language === 'ru' ? `Все`: `All`}</Typography>
                                        </Radio>
                                        {clientsType.map((clientType) => 
                                            <Radio className="filter-admin__form__item" value={clientType.type}>
                                                <Typography className="filter-admin__form__desc">{clientType.title}</Typography>
                                            </Radio>
                                        )}
                                    </Radio.Group>
                                }
                            </div>
                            <div className="filter-admin__form__right__orders-second">
                                <Typography className="filter-admin__form__title">{language === 'ru' ? `Статус заказа:`: `Order status`}</Typography>
                                <Radio.Group className="filter-admin__form__radio-group" onChange={(e) => onChangeStatus(e.target.value)} value={status}>
                                    <Radio className="filter-admin__form__item" value=''>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Все`: `All`}</Typography>
                                    </Radio>
                                    <Radio className="filter-admin__form__item" value='1'>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Ожидается подбор`: `Selection is expected`}</Typography>
                                    </Radio>
                                    <Radio className="filter-admin__form__item" value='2'>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Подбор выполнен`: `Selection is completed`}</Typography>
                                    </Radio>
                                    <Radio className="filter-admin__form__item" value='0'>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Ожидается замер`: `Measurement is expected`}</Typography>
                                    </Radio>
                                </Radio.Group>
                            </div>
                            <div className="filter-admin__form__right__orders-third">
                                <Typography className="filter-admin__form__title">{language === 'ru' ? `Стадия заказа:`: `Order stage:`}</Typography>
                                {rolesLoading ? <Spin className="spin"/>:
                                    <Radio.Group className="filter-admin__form__radio-group" onChange={(e) => onChangeRoleId(e.target.value)} value={roleId}>
                                        <Radio className="filter-admin__form__item" value=''>
                                            <Typography className="filter-admin__form__desc">{language === 'ru' ? `Все`: `All`}</Typography>
                                        </Radio>
                                        {roles.map((role) => 
                                            <Radio className="filter-admin__form__item" value={role.id}>
                                                <Typography className="filter-admin__form__desc">{role.title}</Typography>
                                            </Radio>
                                        )}
                                    </Radio.Group>
                                }
                            </div>
                        </> :
                    (page === 'catalog') ?
                        <>
                            <div className="filter-admin__form__right__catalog-first">
                                {deviceCategoryLoading ? <Spin className="spin"/> :
                                    <Select
                                        placeholder="Функция устройства"
                                        className="filter-admin__form__select"
                                        value={deviceCategoryId}
                                        onChange={handleChangeCategoryDevice}
                                    >
                                        <Option value=''>
                                            <Typography className="filter-admin__form__select__desc">{language === 'ru' ? `Все функции устройств`: `All device functions`}</Typography>
                                        </Option>
                                        {deviceCategory.map((category) =>
                                            <Option value={category.id}>
                                                <Typography className="filter-admin__form__select__desc">{category.title}</Typography>
                                            </Option>
                                        )}
                                    </Select>
                                }
                                {deviceTypeLoading ? <Spin className="spin"/>:
                                    <Select
                                        placeholder="Тип устройства"
                                        value={deviceTypeId}
                                        onChange={handleChangeTypeDevice}
                                    >
                                        <Option value=''>
                                            <Typography className="filter-admin__form__select__desc">{language === 'ru' ? `Все типы устройств`: `All types of devices`}</Typography>
                                        </Option>
                                        {deviceType.map((type) =>
                                            <Option value={type.id}>
                                                <Typography className="filter-admin__form__select__desc">{type.title}</Typography>
                                            </Option>
                                        )}
                                    </Select>
                                }
                            </div>
                            <div className="filter-admin__form__right__catalog-second">
                                <Typography className="filter-admin__form__title">{language === 'ru' ? `Наличие на складе:`: `Stock availability:`}</Typography>
                                <Radio.Group className="filter-admin__form__radio-group" onChange={(e) => onChangeStock(e.target.value)} value={filterCatalog}>
                                    <Radio className="filter-admin__form__item" value=''>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Все`: `All`}</Typography>
                                    </Radio>
                                    <Radio className="filter-admin__form__item" value={true}>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `В наличии`: `In stock`}</Typography>
                                    </Radio>
                                    <Radio value={false}>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Нет в наличии`: `Out of stock`}</Typography>
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </> :
                        <>
                            <div className="filter-admin__form__right__employee-first">
                                <Typography className="filter-admin__form__title">{language === 'ru' ? `Статус сотрудника:`: `Employee status:`}</Typography>
                                <Radio.Group className="filter-admin__form__radio-group" onChange={(e) => onChangeInStaff(e.target.value)} value={inStaff}>
                                    <Radio className="filter-admin__form__item" value=''>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Все`: `All`}</Typography>
                                    </Radio>
                                    <Radio className="filter-admin__form__item" value={1}>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `В штате`: `In the staff`}</Typography>
                                    </Radio>
                                    <Radio value={0}>
                                        <Typography className="filter-admin__form__desc">{language === 'ru' ? `Уволен`: `Dismissed`}</Typography>
                                    </Radio>
                                </Radio.Group>
                            </div>
                            <div className="filter-admin__form__right__employee-second">
                                <Typography className="filter-admin__form__title">{language === 'ru' ? `Стадия заказа:`: `Order stage:`}</Typography>
                                {rolesLoading ? <Spin className="spin"/>:
                                    <Radio.Group className="filter-admin__form__radio-group" onChange={(e) => onChangeRoleId(e.target.value)} value={roleId}>
                                        <Radio className="filter-admin__form__item" value=''>
                                            <Typography className="filter-admin__form__desc">{language === 'ru' ? `Все`: `All`}</Typography>
                                        </Radio>
                                        {roles.map((role) => 
                                            <Radio className="filter-admin__form__item" value={role.id}>
                                                <Typography className="filter-admin__form__desc">{role.title}</Typography>
                                            </Radio>
                                        )}
                                </Radio.Group>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}