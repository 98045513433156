import { Button, Empty, List, Spin, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { useParams } from 'react-router-dom'

import { ReactComponent as ChosenIconOrange } from 'images/chosenIconOrange.svg'
import { ReactComponent as CloseIcon } from 'images/closeListIcon.svg'
import { ReactComponent as ChosenIcon } from 'images/chosenIcon.svg'
import { ReactComponent as MarkerIcon } from 'images/markeIcon.svg'
import { ReactComponent as DeleteIcon } from 'images/deleteIcon.svg'
import { requestAddMarkerMeasurer, requestDeleteMarkerMeasurer, requestDevicesMeasurer,
    requestPlanCommentEdit } from 'redux/configStore'
import { ListElement } from '../ListElement'
import './style.scss'
import { requestDeleteDeviceCategoryMeasurer } from 'redux/configStore/measurer/deviceGategory/action'
import { useContext } from 'react'
import { LanguageContext } from 'helpers'

export const MeasurerPlan = ({disable, dataPlan, markers, markersLoading, loading}) => {
    const parms = useParams()
    const dispatch = useDispatch()

    const id = parms.id
    const { language } = useContext(LanguageContext)
    const [newMarker, setNewMarker] = useState(null)
    const [dataDeviceCategories, setDataDeviceCategories] = useState([])
    const [deviceCategoryToCommentsObj, setDeviceCategoryToCommentsObj] = useState({})

    const [chosenMarkerId, setChosenMarkerId] = useState(null)
    const chosenMarker = useMemo(() => markers?.find(marker => marker.id === chosenMarkerId) ?? null, [chosenMarkerId, markers])
    const chosenMarkerCategories = useMemo(() => chosenMarker?.device_categories ?? [], [chosenMarker])

    const devices = useSelector((state) => state.deviceGategory.deviceGategory)

    const handleMarkerAdd = (event) => {
        if (!disable) {
            const rect = event.target.getBoundingClientRect()
            const x = +(event.clientX - rect.left - 15).toFixed(3)
            const y = +(event.clientY - rect.top - 15).toFixed(3)
            const newMarker = {x, y, isNew: true}
            setNewMarker(newMarker)
        }
        setChosenMarkerId(null)
    }

    const deleteMarker = (markerId) => {
        if (markerId !== undefined && !disable) {
          dispatch(requestDeleteMarkerMeasurer(id, dataPlan.id, markerId))
        }
    }

    const handleMarkerDevices = () => {
        const data = {
            x: newMarker.x, y: newMarker.y, device_categories: dataDeviceCategories,
        }
        dispatch(requestAddMarkerMeasurer(id, dataPlan.id, data))
        setNewMarker(null)
    }

    useEffect(() => {
        dispatch(requestDevicesMeasurer())
    }, [dispatch])

    const handleChosenMarker = (markerId) => {
        setNewMarker(null)
        if (markerId === chosenMarkerId) {
            setChosenMarkerId(null)
        } else {
            setChosenMarkerId(markerId)
        }
    }

    const handleCommentChange = (categoryId, newComment) => {
        setDeviceCategoryToCommentsObj({
            ...deviceCategoryToCommentsObj,
            [categoryId]: newComment
        })
    }

    const handleCategoryCommentSave = (categoryId) => {
        const comment = deviceCategoryToCommentsObj[categoryId]
        dispatch(requestPlanCommentEdit(categoryId, dataPlan.id, { comment }))
    }

    useEffect(() => {
        setDeviceCategoryToCommentsObj(deviceCategoryToCommentsObj => ({
            ...deviceCategoryToCommentsObj,
            ...markers.flatMap(({ device_categories }) => device_categories)
                .filter(deviceCategory => !!deviceCategory.comment)
                .map(deviceCategory => ({
                    id: deviceCategory.id,
                    comment: deviceCategory.comment
                })).reduce((acc, deviceCategoryComment) => ({
                    ...acc,
                    [deviceCategoryComment.id]: deviceCategoryComment.comment
                }), {})
        }))
    }, [chosenMarkerCategories, markers])

    const deleteDevicecategory = (categoryId, device_category, markerId) => {
        dispatch(requestDeleteDeviceCategoryMeasurer(categoryId, dataPlan.id))
        if (!!device_category.length) {
            dispatch(requestDeleteMarkerMeasurer(id, dataPlan.id, markerId))
        }
    }

    return (<>
        {(markersLoading || loading) ? <Spin className="spin"/> : (<div className="plan_img">
            <img src={dataPlan?.path} className="plan" onClick={handleMarkerAdd} alt=''/>
            {[...markers, newMarker].filter(marker => !!marker).map((marker, index) => (<div
                key={index}
                className="marker-measurer"
                style={{
                    top: marker.y, left: marker.x,
                }}
            >
                {marker?.isNew && !disable ? (
                    <MarkerIcon className="marker-measurer__marker-icon" onClick={() => setNewMarker(null)}/>
                ) : (<>
                    {!disable ? 
                        <MarkerIcon className="marker-measurer__marker-icon" onClick={() => deleteMarker(marker.id)}/>:
                        <div className={`marker-measurer__num green ${disable && 'green'}`} onClick={() => deleteMarker(marker.id)}>
                            <Typography className="marker-measurer__num__title">
                                № {index + 1}
                            </Typography>
                        </div>
                    } 

                    {disable ? (<ChosenIcon className="marker-measurer__chosen-icon" onClick={() => handleChosenMarker(marker.id)}/>) : (
                        <ChosenIconOrange className="marker-measurer__chosen-icon" onClick={() => handleChosenMarker(marker.id)}/>)}
                </>)}
            </div>))}
            {chosenMarker && (<div className="marker-wrapper" style={{
                top: chosenMarker.y + 70, left: chosenMarker.x - 142, zIndex: 2
            }}>
                 <CloseIcon onClick={() => setChosenMarkerId(null)} className="marker-wrapper_close"/>
                <List
                    className="list-element"
                    dataSource={chosenMarkerCategories}
                    locale={{
                        emptyText: (
                            <Empty description={language === 'ru'? `Нет данных для отображения`: `No data to display`} />
                        )
                    }}
                    renderItem={(item) => (
                        <div key={item.id}>
                            <div className="marker-measurer_wrapper">
                                <Typography className="marker-measurer_device-title">{item.device_category.title}</Typography>
                                {!disable && <DeleteIcon className="marker-measurer_wrapper_icon-delete cursor" onClick={() => deleteDevicecategory(item.id, item.device_category, chosenMarker.id)}/> }
                            </div>
                            <div className="marker-measurer__coment">
                                <TextArea
                                    className="marker-measurer__coment_title"
                                    value={deviceCategoryToCommentsObj[item.id]}
                                    onChange={({ target: { value }}) => handleCommentChange(item.id, value)}
                                    disabled={disable}
                                />
                            </div>
                            {!disable &&
                                <div className="marker-measurer__save">
                                    <Button
                                        type="primary"
                                        disabled={item.comment === deviceCategoryToCommentsObj[item.id] || !deviceCategoryToCommentsObj[item.id]}
                                        onClick={() => handleCategoryCommentSave(item.id)}
                                        className="marker-measurer__save_button"
                                    >
                                        <Typography className="marker-measurer__save_button_title">{language === 'ru'? `Сохранить`: `Save`}</Typography>
                                    </Button>
                                </div>
                            }
                        </div>
                    )}
                />
            </div>)}
            {newMarker && (<div className="marker-wrapper" style={{
                top: newMarker.y + 30, left: newMarker.x - 142, zIndex: 2
            }}>
                <ListElement
                    data={devices}
                    dataDeviceCategories={dataDeviceCategories}
                    setDataDeviceCategories={setDataDeviceCategories}
                />
                <Button className="marker-btn" onClick={handleMarkerDevices}>
                    <Typography className="marker-btn_title">Добавить</Typography>
                </Button>
            </div>)}
        </div>)}
    </>)
}
