import { HTTP_METHODS } from 'helpers'
import { Fetcher } from 'service'

const fetcher = new Fetcher()

export const requestActionsPickerApi = (id, lang) =>
    fetcher.requestToReceive({
        url: `picker/clients/${id}/actions`,
        params: {lang},
        method: HTTP_METHODS.GET
    })