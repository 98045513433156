import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'

import { requestAddImageAdmin, requestAddVideoAdmin, requestImageAddMeasurer, requestVideoAddMeasurer } from 'redux/configStore'
import { ReactComponent as PhotoDisable } from 'images/photoDisableIcon.svg'
import { ReactComponent as VideoDisable } from 'images/videoDisableIcon.svg'
import { ReactComponent as PhotoIcon } from 'images/photoIcon.svg'
import { ReactComponent as VideoIcon } from 'images/videoIcon.svg'
import './style.scss'

export const ImageVideoUploader = ({ role, image, errorPlan, disable }) => {
    const params = useParams()
    const dispatch = useDispatch()

    const id = params.id

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0]
        const formData = new FormData()

        if (selectedFile) {
            formData.append('path', selectedFile, selectedFile.name)
            formData.append('client_id', id)

            const reader = new FileReader()
            reader.readAsDataURL(selectedFile)

            reader.onloadend = () => {
                const data = {
                    path: reader.result.split(',')[1]
                }
                formData.append('data', JSON.stringify(data))
                if (role === 'admin'){
                    dispatch(requestAddImageAdmin(id, formData))
                } else {
                    dispatch(requestImageAddMeasurer(id, formData))
                }
            }
        }
    }

    const handleVideoChange = (event) => {
        const selectedFile = event.target.files[0]
  
        if (selectedFile) {
            if (selectedFile.type.indexOf('video/') !== 0) {
                alert('Выбранный файл не является видео.')
                return
            }
    
            if (!id || typeof id !== 'string') {
                alert('Не удалось получить id клиента.')
                return
            }
    
            const formData = new FormData()
            formData.append('path', selectedFile, selectedFile.name)
            formData.append('client_id', id)
        
            const reader = new FileReader()
            reader.readAsDataURL(selectedFile)
        
            reader.onloadend = () => {
                const data = {
                    path: reader.result.split(',')[1]
                }
        
                const video = document.createElement('video')
                video.preload = 'metadata'
                video.onloadedmetadata = () => {
                    if (video.duration > 10) {
                        notification.open({
                            message: 'Видео слишком большое',
                            description: 'Загрузите видео до 10 секунд',
                        })
                        return
                    }
            
                    formData.append('data', JSON.stringify(data))
                    if (role === 'admin'){
                        dispatch(requestAddVideoAdmin(id, formData))
                    } else {
                        dispatch(requestVideoAddMeasurer(id, formData))
                    }
                }
                video.src = URL.createObjectURL(selectedFile)
            }
        }
    }

  return (
    <div className="img-video-uploader no-printme">
        {(image && !errorPlan) &&
            <>
            {!disable ?
                <form className="img-array"  encType="multipart/form-data">
                    <label
                        htmlFor="img-array-button"
                    >
                        <PhotoIcon className="img-array__icon"/>
                    </label>
                    <input
                        multiple
                        type="file"
                        accept="image/*"
                        id="img-array-button"
                        className="btn-group-uploader__btn"
                        onChange={handleImageChange}
                    />
                </form>:
                <PhotoDisable className="img-array__icon-diable"/>
                }
                {!disable ?
                    <form className="video-array"  encType="multipart/form-data">
                        <label
                            htmlFor="video-array-button"
                        >
                            <VideoIcon className="video-array__icon" />
                        </label>
                        <input
                            multiple
                            type="file"
                            accept="video/*"
                            id="video-array-button"
                            className="btn-group-uploader__btn"
                            onChange={handleVideoChange}
                        />
                    </form>:
                    <VideoDisable className="video-array__icon-diable"/>
                }
            </>
        }
    </div>
  )
}