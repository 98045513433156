import { deviceSelectionSliceLoadSuccess, deviceSelectionAdminAddAdmin, error, finish, loading, setDeviceSelectionId } from './deviceSelectionSlice'
import { markersAdminLoadSuccess, markerDeviceSelectionEditSuccessAdmin } from '../markersAdmin/markersAdminSlice'
import { requestEstimateAdmin } from '../estimate'
import { requestMarkersAdmin } from '../markersAdmin'
import { requestAddDeviceSelectionAdminApi, requestDeleteDeviceSelectionAdminApi, requestDevicesSelcetionAdminApi, requestEditCountDeviceSelectionAdminApi, requestEditDeviceSelectionAdminApi } from 'utils/admin/devices/api'
import { requestDeleteDeviceCategoryAdminApi } from 'utils/admin/client/api'

export const requestDevicesSelectionAdmin = (categoryId, lang) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDevicesSelcetionAdminApi(categoryId, lang)
        if(responce.data) {
            dispatch(deviceSelectionSliceLoadSuccess(responce.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestAddDeviceSelectionAdmin = (data, planId, clientId) => async (dispatch) => {
    try {
        dispatch(loading())
        const response1 = await requestAddDeviceSelectionAdminApi(data)
        if (response1.data.data) {
            dispatch(deviceSelectionAdminAddAdmin(response1.data.data))
        }
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersAdmin(planId))
        dispatch(requestEstimateAdmin(clientId))
    }
}

export const requestEditDeviceSelectionAdmin = (id, data, planId) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditDeviceSelectionAdminApi(id, data)
        const responce = await requestMarkersAdmin(planId)
        dispatch(markersAdminLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestEditCountDeviceSelectionAdmin = (markerId, deviceCategoryId, deviceSelectionId, data, idClient) => async (dispatch) => {
    try {
        dispatch(loading())
        dispatch(setDeviceSelectionId(deviceSelectionId))
        const response = await requestEditCountDeviceSelectionAdminApi(deviceSelectionId, data)
        if (response.data.data) {
            dispatch(markerDeviceSelectionEditSuccessAdmin({
                markerId,
                deviceCategoryId,
                deviceSelection: response.data.data
            }))
        }
    } catch (err) {
        dispatch(setDeviceSelectionId(null))
        dispatch(error({ error: err }))
    } finally {
        dispatch(setDeviceSelectionId(null))
        dispatch(finish())
        dispatch(requestEstimateAdmin(idClient))
    }
}

export const requestDeleteDeviceSelectionAdmin = (deviceId, planId, clientId) => async (dispatch) => {
    try {
        await requestDeleteDeviceSelectionAdminApi(deviceId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersAdmin(planId))
        dispatch(requestEstimateAdmin(clientId))
    }
}

export const requestDeleteDeviceCategoryAdmin = (id, planId) => async (dispatch) => {
    try {
        await requestDeleteDeviceCategoryAdminApi(id)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestMarkersAdmin(planId))
    }
}