import { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Spin, Input, Form } from 'antd'

import { requestManufacturerAdmin, requesAddManufacturerAdmin, requesEditManufacturerAdmin, requestDeleteManufacturerAdmin } from 'redux/configStore'
import { ReactComponent as IconDelete } from 'images/deleteIcon.svg'
import { ReactComponent as IconEdit } from 'images/editIcon.svg'
import { ReactComponent as IconAdd } from 'images/addIcon.svg'
import { ButtonPrimary, Modal } from 'components/_atoms'
import { LanguageContext } from 'helpers'
import './style.scss'

export const SettingsMenuManufacturer = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const { language } = useContext(LanguageContext)

    const manufacturers = useSelector((state) => state.manufacturerAdmin.manufacturerAdmin)
    const manufacturersLoading = useSelector((state) => state.manufacturerAdmin.isLoading)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [btnTitle, setBtnTitle] = useState('')
    const [formLabel, setFormLabel] = useState('')
    const [categoryId, setCategoryId] = useState(null)
    
    const handleAddManufacturer = () => {
        setBtnTitle(`${language === 'ru' ? `Добавить производителя`: `Add a manufacturer`}`)
        setFormLabel(`${language === 'ru' ? `Введите производителя`: `Add a manufacturer`}`)
        showModal()
    }

    const handleChangeManufacturer = (idManufacturer, categoryTitle) => {
        setBtnTitle(`${language === 'ru' ? `Изменить производителя`: `Change the manufacturer`}`)
        setFormLabel(`${language === 'ru' ? `Изменить производителя`: `Change the manufacturer`}`)
        setCategoryId(idManufacturer)
        showModal()
    }

    const handleDeleteManufacturer = (idManufacturer) => {
        dispatch(requestDeleteManufacturerAdmin(idManufacturer))
    }

    const onFinish = (values) => {
        if(formLabel === 'Введите производителя' || formLabel === 'Add a manufacturer') {
            const data = {
                title: values.title
            }
            dispatch(requesAddManufacturerAdmin(data))
        }
        if(btnTitle === 'Изменить производителя' || btnTitle === 'Change the manufacturer') {
            const data = {
                title: values.title
            }
            dispatch(requesEditManufacturerAdmin(categoryId, data))
        }
        form.resetFields()
        handleCancel()
    } 

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        dispatch(requestManufacturerAdmin(language))
    }, [language])

    return (
        <div className="settings-menu-protocol">
            <div className="settings-menu__header">
                <Typography className="settings-menu__header__title">{language === 'ru' ? `Настройки производителей`: `Manufacturer settings`}</Typography>
                <IconAdd className="settings-menu__header__icon" onClick={handleAddManufacturer}/>
            </div>
            {manufacturersLoading ? <Spin className="spin-list"/> :
                <>
                    {manufacturers.map((manufacturer) => 
                            <Typography className="settings-menu-protocol__item-title">
                                <IconEdit
                                    className="settings-menu__icon-edit"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleChangeManufacturer(manufacturer.id, manufacturer.title)
                                    }}
                                />
                                <IconDelete className="settings-menu__icon-delete"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleDeleteManufacturer(manufacturer.id)
                                    }}
                                />
                                {manufacturer.title}
                            </Typography>
                    )}
                </>
            }
            <Modal
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
                component={
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off" className="settings-menu__modal"
                    >
                        <Form.Item
                            name="title"
                            label={formLabel}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <ButtonPrimary text={btnTitle} />
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}