import { requestAddDevicePickerApi, requestDeleteDeviceSelectionPickerApi, requestDevicesPickerApi, requestEditCountDevicePickerApi, requestEditDevicePickerApi, requestPlanPickerMarkerApi } from 'utils'
import { clientDevicesPickerLoadSuccess, error, finish, loading, clientAddDeivcesPicker, setDeviceSelectionId } from './devicesSlice'
import { markerDeviceSelectionEditSuccess, markerLoadSuccess } from '../marker/markerSlice'
import { requestEstimatePicker } from '../estimate'
import { requestPlanMarkerPicker } from '../marker'

export const requestDevicesPicker = (categoryId) => async (dispatch) => {
    try {
        dispatch(loading())
        const responce = await requestDevicesPickerApi(categoryId)
        if(responce.data) {
            dispatch(clientDevicesPickerLoadSuccess(responce.data))
        }
    } catch (err) {
        dispatch(error({error: err}))
    } finally {
        dispatch(finish())
    }
}

export const requestAddDevicePicker = (data, planId, clientId) => async (dispatch) => {
    try {
        dispatch(loading())
        const response1 = await requestAddDevicePickerApi(data)
        if (response1.data.data) {
            dispatch(clientAddDeivcesPicker(response1.data.data))
        }
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPlanMarkerPicker(planId))
        dispatch(requestEstimatePicker(clientId))
    }
}

export const requestEditDevicePicker = (id, data, planId) => async (dispatch) => {
    try {
        dispatch(loading())
        await requestEditDevicePickerApi(id, data)
        const responce = await requestPlanPickerMarkerApi(planId)
        dispatch(markerLoadSuccess(responce.data.data))
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
    }
}

export const requestEditCountDevicePicker = (markerId, deviceCategoryId, deviceSelectionId, data, idClient) => async (dispatch) => {
    try {
        dispatch(loading())
        dispatch(setDeviceSelectionId(deviceSelectionId))
        const response = await requestEditCountDevicePickerApi(deviceSelectionId, data)
        if (response.data.data) {
            dispatch(markerDeviceSelectionEditSuccess({
                markerId,
                deviceCategoryId,
                deviceSelection: response.data.data
            }))
        }
    } catch (err) {
        dispatch(setDeviceSelectionId(null))
        dispatch(error({ error: err }))
    } finally {
        dispatch(setDeviceSelectionId(null))
        dispatch(finish())
        dispatch(requestEstimatePicker(idClient))
    }
}

export const requestDeleteDeviceSelectionPicker = (deviceId, planId, clientId) => async (dispatch) => {
    try {
        await requestDeleteDeviceSelectionPickerApi(deviceId)
    } catch (err) {
        dispatch(error({ error: err }))
    } finally {
        dispatch(finish())
        dispatch(requestPlanMarkerPicker(planId))
        dispatch(requestEstimatePicker(clientId))
    }
}