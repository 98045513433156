import { Form, Input, notification } from 'antd'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as LogoIcon } from 'images/logoIcon.svg'
import { ButtonPrimary } from 'components/_atoms'
import { requestSignIn } from 'utils'
import { routes } from 'router'
import './style.scss'

export const SignIn = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const onFinish = (values) => {
        const data = {
            login: values.login,
            password: values.password,
        }

        requestSignIn(data)
            .then((async res => {
                await localStorage.setItem('token', res.data.data.access_token)
                navigate(routes.home)
            })).catch(err => {
                notification.open({
                    message: 'Неправильно введены данные',
                    description: 'Попробуйте снова',
                })
            })
    }

    return (
        <div className="sign-in-wrapper">
             <div className="sign-in">
                <LogoIcon className="sign-in__img" />
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="login"
                        label="Логин"
                     >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Пароль"
                     >
                        <Input.Password className="input-password"/>
                    </Form.Item>
                    <Form.Item>
                        <ButtonPrimary text="Вход" />
                    </Form.Item>
                </Form>
             </div>
        </div>
    )
}